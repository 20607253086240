import actions from './actions';

const {
  CHARGE_REPORT_LIST_BEGIN,
  CHARGE_REPORT_LIST_SUCCESS,
  CHARGE_REPORT_LIST_ERR,
} = actions;

const initState = {
  list: null,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const chargeReportReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CHARGE_REPORT_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CHARGE_REPORT_LIST_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case CHARGE_REPORT_LIST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default chargeReportReducer;
