import actions from './actions';

const {
  LISTING_BEGIN,
  LISTING_SUCCESS,
  LISTING_ERR,
  GET_LISTING_BEGIN,
  GET_LISTING_SUCCESS,
  GET_LISTING_ERR,
  LISTING_DELETE_BEGIN,
  LISTING_DELETE_SUCCESS,
  LISTING_DELETE_ERR,
  LISTING_CREATE_UPDATE_BEGIN,
  LISTING_CREATE_UPDATE_SUCCESS,
  LISTING_CREATE_UPDATE_ERR,
  HOST_PARTNER_COVERAGE_ERR,
  HOST_PARTNER_COVERAGE_SUCCESS,
  HOST_PARTNER_COVERAGE_BEGIN,
  DOWNLOAD_QRCODE_ERR,
  DOWNLOAD_QRCODE_SUCCESS,
  DOWNLOAD_QRCODE_BEGIN,
  LISTING_CONTENT_BEGIN,
  LISTING_CONTENT_SUCCESS,
  LISTING_CONTENT_ERR,
  CREATE_CHAT_STATUS_BEGIN,
  CREATE_CHAT_STATUS_SUCCESS,
  CREATE_CHAT_STATUS_ERR,
} = actions;

const initState = {
  list: null,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const ListingReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LISTING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LISTING_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case LISTING_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_LISTING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_LISTING_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_LISTING_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LISTING_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LISTING_DELETE_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case LISTING_DELETE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LISTING_CREATE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LISTING_CREATE_UPDATE_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case LISTING_CREATE_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case HOST_PARTNER_COVERAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case HOST_PARTNER_COVERAGE_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case HOST_PARTNER_COVERAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
      case DOWNLOAD_QRCODE_BEGIN:
        return {
            ...state,
            loading: true,
          };
      case DOWNLOAD_QRCODE_SUCCESS:
        return {
            ...state,
            list: data,
            loading: false,
          };
      case DOWNLOAD_QRCODE_ERR:
        return {
            ...state,
            error: err,
            loading: false,
          };
      case LISTING_CONTENT_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case LISTING_CONTENT_SUCCESS:
        return {
          ...state,
          list: data,
          loading: false,
        };
      case LISTING_CONTENT_ERR:
        return {
          ...state,
          error: err,
          loading: false,
        };
        case CREATE_CHAT_STATUS_BEGIN:
          return {
            ...state,
            loading: true,
          };
        case CREATE_CHAT_STATUS_SUCCESS:
          return {
            ...state,
            list: data,
            loading: false,
          };
        case CREATE_CHAT_STATUS_ERR:
          return {
            ...state,
            error: err,
            loading: false,
          };
    default:
      return state;
  }
};
export default ListingReducer;
