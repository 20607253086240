import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Input, Modal } from 'antd';
import { Main, CardToolbox, BasicFormWrapper } from '../../styled.js';
import { Button } from '../../../components/buttons/buttons.js';
import { AddUser } from '../style.js';
import HttpStatus from '../../../utility/status.enum.js';
// import { Link } from 'react-router-dom';
import Notification from '../../../utility/notification.js';
import Spinner from '../../common/Spinner.js';
import { changePassword } from '../../../redux/manageHost/actionCreator';

function ChangePassword({ changepasswordModal, hidechangepasswordModal, props,handleChangePasswordInputChange }) {
  const { backEndPrefix } = global;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [pageTitle, setPageTitle] = useState('Change Password');
  const [buttonName, setButtonName] = useState('Update');
  const isLoading = useSelector((state) => state.Host.loading);
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const getUser = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    form.resetFields();
  }, [changepasswordModal]);

  const handleSubmit = useCallback(
    (values) => {
      setSpinnerLoader(true);
      let slug = getUser?.slug;
      let requestData = { ...values, slug };

      const savePassword = async () => {
        try {
          const passwordResponse = await dispatch(
            changePassword(requestData, (response) => {
              return response;
            }),
          );
          if (passwordResponse?.status !== HttpStatus.HTTP_OK) {
            Notification.addNotificationError(passwordResponse.message);
          } else {
            Notification.addNotificationSuccess(passwordResponse.message);
            form.resetFields();
            hidechangepasswordModal(false);
          }
          setSpinnerLoader(false);
        } catch (error) {          
        }
      };
      savePassword();

    },
    [navigate, dispatch],
  );

  return (
    <>     
      <Modal
        title={pageTitle}
        visible={changepasswordModal}
        footer={null}
        onCancel={hidechangepasswordModal}
        key={2}
        maskClosable={false}
      >
        <Spinner loader={spinnerLoader} />
        <Form form={form} name="change-password" onFinish={handleSubmit} onValuesChange={handleChangePasswordInputChange}>
          <AddUser>
            <BasicFormWrapper>
              <Row gutter={15}>
                <Col xs={24}>
                  <div className="project-modal">
                    <Form.Item
                      label="Current Password"
                      name="current_password"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter current password',
                        },
                      ]}
                    >
                      <Input.Password placeholder="Enter current password" className='wifi' tabIndex="1" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={24}>
                  <div className="project-modal">
                    <Form.Item
                      label="New Password"
                      name="new_password"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter new password',
                        },
                        {
                          min: 8,
                          message: 'Password must be at least 8 characters long',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('current_password') !== value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password and old password must be different'));
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder="Enter new password" className='wifi' tabIndex="2" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={24}>
                  <div className="project-modal">
                    <Form.Item
                      name="confirm_password"
                      label="Confirm New Password"
                      dependencies={['new_password']}
                      rules={[
                        { required: true, message: 'Please enter confirm new password' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('new_password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password and confirmation new password do not match'));
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder="Enter confirm new password" tabIndex="3" className='wifi' />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={15} className="mt-20">
                <Col xs={24}>
                    <Button htmlType="submit" size="default" shape="circle" type="dark" key="submit" className="w-100">
                      {isLoading ? 'Loading...' : buttonName}
                    </Button>
                </Col>
              </Row>
            </BasicFormWrapper>
          </AddUser>
        </Form>        
      </Modal>
    </>
  );
}

export default ChangePassword;
