const actions = {
  WAIVER_PAYOUT_LIST_BEGIN: 'WAIVER_PAYOUT_LIST_BEGIN',
  WAIVER_PAYOUT_LIST_SUCCESS: 'WAIVER_PAYOUT_LIST_SUCCESS',
  WAIVER_PAYOUT_LIST_ERR: 'WAIVER_PAYOUT_LIST_ERR',

  WAIVER_PAYOUT_DETAIL_BEGIN: 'WAIVER_PAYOUT_DETAIL_BEGIN',
  WAIVER_PAYOUT_DETAIL_SUCCESS: 'WAIVER_PAYOUT_DETAIL_SUCCESS',
  WAIVER_PAYOUT_DETAIL_ERR: 'WAIVER_PAYOUT_DETAIL_ERR',

  PARTNER_COVERAGE_LIST_BEGIN: 'PARTNER_COVERAGE_LIST_BEGIN',
  PARTNER_COVERAGE_LIST_SUCCESS: 'PARTNER_COVERAGE_LIST_SUCCESS',
  PARTNER_COVERAGE_LIST_ERR: 'PARTNER_COVERAGE_LIST_ERR',

  CHANGE_ORDER_STATUS_BEGIN: 'CHANGE_ORDER_STATUS_BEGIN',
  CHANGE_ORDER_STATUS_SUCCESS: 'CHANGE_ORDER_STATUS_SUCCESS',
  CHANGE_ORDER_STATUS_ERR: 'CHANGE_ORDER_STATUS_ERR',

  STRIPE_PAYMENT_REFUND_BEGIN: 'STRIPE_PAYMENT_REFUND_BEGIN',
  STRIPE_PAYMENT_REFUND_SUCCESS: 'STRIPE_PAYMENT_REFUND_SUCCESS',
  STRIPE_PAYMENT_REFUND_ERR: 'STRIPE_PAYMENT_REFUND_ERR',

  partnerCoverageListingBegin: () => {
    return {
      type: actions.PARTNER_COVERAGE_LIST_BEGIN,
    };
  },
  partnerCoverageListingSuccess: (data) => {
    return {
      type: actions.PARTNER_COVERAGE_LIST_SUCCESS,
      data,
    };
  },
  partnerCoverageListingErr: (err) => {
    return {
      type: actions.PARTNER_COVERAGE_LIST_ERR,
      err,
    };
  },
  waiverPayoutListingBegin: () => {
    return {
      type: actions.WAIVER_PAYOUT_LIST_BEGIN,
    };
  },
  waiverPayoutListingSuccess: (data) => {
    return {
      type: actions.WAIVER_PAYOUT_LIST_SUCCESS,
      data,
    };
  },
  waiverPayoutListingErr: (err) => {
    return {
      type: actions.WAIVER_PAYOUT_LIST_ERR,
      err,
    };
  },
  waiverPayoutDetailBegin: () => {
    return {
      type: actions.WAIVER_PAYOUT_DETAIL_BEGIN,
    };
  },
  waiverPayoutDetailSuccess: (data) => {
    return {
      type: actions.WAIVER_PAYOUT_DETAIL_SUCCESS,
      data,
    };
  },
  waiverPayoutDetailErr: (err) => {
    return {
      type: actions.WAIVER_PAYOUT_DETAIL_ERR,
      err,
    };
  },
  changeOrderStatusBegin: () => {
    return {
      type: actions.CHANGE_ORDER_STATUS_BEGIN,
    };
  },
  changeOrderStatusSuccess: (data) => {
    return {
      type: actions.CHANGE_ORDER_STATUS_SUCCESS,
      data,
    };
  },
  changeOrderStatusErr: (err) => {
    return {
      type: actions.CHANGE_ORDER_STATUS_ERR,
      err,
    };
  },
  stripePaymentRefundBegin: () => {
    return {
      type: actions.STRIPE_PAYMENT_REFUND_BEGIN,
    };
  },
  stripePaymentRefundSuccess: (data) => {
    return {
      type: actions.STRIPE_PAYMENT_REFUND_SUCCESS,
      data,
    };
  },
  stripePaymentRefundErr: (err) => {
    return {
      type: actions.STRIPE_PAYMENT_REFUND_ERR,
      err,
    };
  },
};
export default actions;
