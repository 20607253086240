import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import HttpStatus from '../../utility/status.enum';

const { getItemsBegin, getItemsSuccess, getItemsErr } = actions;

const threadListAPI = `/get-thread`;
const messageListAPI = `/get-message`;
const messagePostAPI = `/post-message`;
const aiEnableDisableAPI = `/ai-enable-disable`;
const threadDetailAPI = `/thread-details`;
const markAsReadAPI = `mark-as-read`;
const guestDetailAPI = `/message-thread-reservation-detail/`
const unreadMessageAPI = `/get-unread-count`

const getThreadList = (values) => {
  return async (dispatch) => {
    dispatch(getItemsBegin());
    try {
      const response = await DataService.post(threadListAPI, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(getItemsErr(response?.data?.message));
      } else {
        dispatch(getItemsSuccess(response?.data));
      }
      return response?.data;
    } catch (err) {
      dispatch(getItemsErr(err));
    }
  };
};

const getMessages = (values) => {
  return async (dispatch) => {
    dispatch(getItemsBegin());
    try {
      const response = await DataService.post(messageListAPI, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(getItemsErr(response?.data?.message));
      } else {
        dispatch(getItemsSuccess(response?.data));
      }
      return response?.data;
    } catch (err) {
      dispatch(getItemsErr(err));
    }
  };
};

const postMessage = (values) => {
  return async (dispatch) => {
    dispatch(getItemsBegin());
    try {
      const response = await DataService.post(messagePostAPI, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(getItemsErr(response?.data?.message));
      } else {
        dispatch(getItemsSuccess(response?.data));
      }
      return response?.data?.result;
    } catch (err) {
      dispatch(getItemsErr(err));
    }
  };
};

const aiEnableDisable = (values) => {
  return async (dispatch) => {
    dispatch(getItemsBegin());
    try {
      const response = await DataService.post(aiEnableDisableAPI, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(getItemsErr(response?.data?.message));
      } else {
        dispatch(getItemsSuccess(response?.data));
      }
      return response?.data;
    } catch (err) {
      dispatch(getItemsErr(err));
    }
  };
};

const getThreadDetail = (values) => {
  return async (dispatch) => {
    dispatch(getItemsBegin());
    try {
      const response = await DataService.post(threadDetailAPI, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(getItemsErr(response?.data?.message));
      } else {
        dispatch(getItemsSuccess(response?.data));
      }
      return response?.data?.result;
    } catch (err) {
      dispatch(getItemsErr(err));
    }
  };
};

const markAsRead = (values) => {
  return async (dispatch) => {
    dispatch(getItemsBegin());
    try {
      const response = await DataService.post(markAsReadAPI, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(getItemsErr(response?.data?.message));
      } else {
        dispatch(getItemsSuccess(response?.data));
      }
      return response?.data?.result;
    } catch (err) {
      dispatch(getItemsErr(err));
    }
  };
};


const guestDetail = (value) => {
  return async (dispatch) => {
    dispatch(getItemsBegin());
    try {
      const response = await DataService.get(guestDetailAPI + value);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(getItemsErr(response?.data?.message));
      } else {
        dispatch(getItemsSuccess(response?.data));
      }
      return response?.data?.result;
    } catch (err) {
      dispatch(getItemsErr(err));
    }
  };
};

const getUnreadMessage = () => {
  return async (dispatch) => {
    dispatch(getItemsBegin());
    try {
      const response = await DataService.get(unreadMessageAPI);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(getItemsErr(response?.data?.message));
      } else {
        dispatch(getItemsSuccess(response?.data));
      }
      return response?.data;
    } catch (err) {
      dispatch(getItemsErr(err));
    }
  };
};

export { getThreadList, getMessages, postMessage, getThreadDetail, aiEnableDisable, markAsRead, guestDetail, getUnreadMessage };
