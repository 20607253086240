const actions = {
  ITEM_LIST_BEGIN: 'ITEM_LIST_BEGIN',
  ITEM_LIST_SUCCESS: 'ITEM_LIST_SUCCESS',
  ITEM_LIST_ERR: 'ITEM_LIST_ERR',
  SET_GUEST_LIST: 'SET_GUEST_LIST',
  SET_GUEST_LAST_PAGE: 'SET_GUEST_LAST_PAGE',
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  SET_SELECTED_THREAD: 'SET_SELECTED_THREAD',

  getItemsBegin: () => {
    return {
      type: actions.ITEM_LIST_BEGIN,
    };
  },
  getItemsSuccess: (data) => {
    return {
      type: actions.ITEM_LIST_SUCCESS,
      data,
    };
  },
  getItemsErr: (err) => {
    return {
      type: actions.ITEM_LIST_ERR,
      err,
    };
  },

  storeGuestList: (list) => ({
    type: actions.SET_GUEST_LIST,
    payload: list,
  }),
  
  storeGuestLastPage: (lastPage) => ({
    type: actions.SET_GUEST_LAST_PAGE,
    payload: lastPage,
  }),

  storeSearchText: (searchText) => ({
    type: actions.SET_SEARCH_TEXT,
    payload: searchText,
  }),

  storeSelectedThread: (thread) => ({
    type: actions.SET_SELECTED_THREAD,
    payload: thread,
  }),
};
export default actions;
