const actions = {
  CMS_LIST_BEGIN: 'CMS_LIST_BEGIN',
  CMS_LIST_SUCCESS: 'CMS_LIST_SUCCESS',
  CMS_LIST_ERR: 'CMS_LIST_ERR',

  CMS_UPDATE_BEGIN: 'CMS_UPDATE_BEGIN',
  CMS_UPDATE_SUCCESS: 'CMS_UPDATE_SUCCESS',
  CMS_UPDATE_ERR: 'CMS_UPDATE_ERR',

  GET_CMS_BEGIN: 'GET_CMS_BEGIN',
  GET_CMS_SUCCESS: 'GET_CMS_SUCCESS',
  GET_CMS_ERR: 'GET_CMS_ERR',

  CMS_DELETE_BEGIN: 'CMS_DELETE_BEGIN',
  CMS_DELETE_SUCCESS: 'CMS_DELETE_SUCCESS',
  CMS_DELETE_ERR: 'CMS_DELETE_ERR',

  cmsListingBegin: () => {
    return {
      type: actions.CMS_LIST_BEGIN,
    };
  },
  cmsListingSuccess: (data) => {
    return {
      type: actions.CMS_LIST_SUCCESS,
      data,
    };
  },

  cmsListingErr: (err) => {
    return {
      type: actions.CMS_LIST_ERR,
      err,
    };
  },
  updateCmsBegin: () => {
    return {
      type: actions.CMS_UPDATE_BEGIN,
    };
  },
  updateCmsSuccess: (data) => {
    return {
      type: actions.CMS_UPDATE_SUCCESS,
      data,
    };
  },

  updateCmsErr: (err) => {
    return {
      type: actions.CMS_UPDATE_ERR,
      err,
    };
  },
  getCmsBegin: () => {
    return {
      type: actions.GET_CMS_BEGIN,
    };
  },
  getCmsSuccess: (data) => {
    return {
      type: actions.GET_CMS_SUCCESS,
      data,
    };
  },

  getCmsErr: (err) => {
    return {
      type: actions.GET_CMS_ERR,
      err,
    };
  },
  cmsDeleteBegin: () => {
    return {
      type: actions.CMS_DELETE_BEGIN,
    };
  },

  cmsDeleteSuccess: (data) => {
    return {
      type: actions.CMS_DELETE_SUCCESS,
      data,
    };
  },

  cmsDeleteErr: (err) => {
    return {
      type: actions.CMS_DELETE_ERR,
      err,
    };
  },
};
export default actions;
