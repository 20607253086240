const actions = {
  HOST_LIST_BEGIN: 'HOST_LIST_BEGIN',
  HOST_LIST_SUCCESS: 'HOST_LIST_SUCCESS',
  HOST_LIST_ERR: 'HOST_LIST_ERR',

  HOST_CREATE_BEGIN: 'HOST_CREATE_BEGIN',
  HOST_CREATE_SUCCESS: 'HOST_CREATE_SUCCESS',
  HOST_CREATE_ERR: 'HOST_CREATE_ERR',

  GET_HOST_BEGIN: 'GET_HOST_BEGIN',
  GET_HOST_SUCCESS: 'GET_HOST_SUCCESS',
  GET_HOST_ERR: 'GET_HOST_ERR',

  HOST_DELETE_BEGIN: 'HOST_DELETE_BEGIN',
  HOST_DELETE_SUCCESS: 'HOST_DELETE_SUCCESS',
  HOST_DELETE_ERR: 'HOST_DELETE_ERR',

  FETCH_COVERAGE_BEGIN: 'FETCH_COVERAGE_BEGIN',
  FETCH_COVERAGE_SUCCESS: 'FETCH_COVERAGE_SUCCESS',
  FETCH_COVERAGE_ERR: 'FETCH_COVERAGE_ERR',

  CAN_TURN_OFF_BEGIN: 'CAN_TURN_OFF_BEGIN',
  CAN_TURN_OFF_SUCCESS: 'CAN_TURN_OFF_SUCCESS',
  CAN_TURN_OFF_ERR: 'CAN_TURN_OFF_ERR',

  CAN_DELETE_HOST_BEGIN: 'CAN_DELETE_HOST_BEGIN',
  CAN_DELETE_HOST_SUCCESS: 'CAN_DELETE_HOST_SUCCESS',
  CAN_DELETE_HOST_ERR: 'CAN_DELETE_HOST_ERR',


  fetchCoverageBegin: () => {
    return {
      type: actions.FETCH_COVERAGE_BEGIN,
    };
  },
  fetchCoverageSuccess: (data) => {
    return {
      type: actions.FETCH_COVERAGE_SUCCESS,
      data,
    };
  },
  fetchCoverageErr: (err) => {
    return {
      type: actions.FETCH_COVERAGE_ERR,
      err,
    };
  },

  hostListingBegin: () => {
    return {
      type: actions.HOST_LIST_BEGIN,
    };
  },
  hostListingSuccess: (data) => {
    return {
      type: actions.HOST_LIST_SUCCESS,
      data,
    };
  },

  hostListingErr: (err) => {
    return {
      type: actions.HOST_LIST_ERR,
      err,
    };
  },
  createHostBegin: () => {
    return {
      type: actions.HOST_CREATE_BEGIN,
    };
  },
  createHostSuccess: (data) => {
    return {
      type: actions.HOST_CREATE_SUCCESS,
      data,
    };
  },

  createHostErr: (err) => {
    return {
      type: actions.HOST_CREATE_ERR,
      err,
    };
  },
  getHostBegin: () => {
    return {
      type: actions.GET_HOST_BEGIN,
    };
  },
  getHostSuccess: (data) => {
    return {
      type: actions.GET_HOST_SUCCESS,
      data,
    };
  },

  getHostErr: (err) => {
    return {
      type: actions.GET_HOST_ERR,
      err,
    };
  },
  hostDeleteBegin: () => {
    return {
      type: actions.HOST_DELETE_BEGIN,
    };
  },

  hostDeleteSuccess: (data) => {
    return {
      type: actions.HOST_DELETE_SUCCESS,
      data,
    };
  },

  hostDeleteErr: (err) => {
    return {
      type: actions.HOST_DELETE_ERR,
      err,
    };
  },

  canTurnOffBegin: () => {
    return {
      type: actions.CAN_TURN_OFF_BEGIN,
    };
  },
  canTurnOffSuccess: (data) => {
    return {
      type: actions.CAN_TURN_OFF_SUCCESS,
      data,
    };
  },
  canTurnOffErr: (err) => {
    return {
      type: actions.CAN_TURN_OFF_ERR,
      err,
    };
  },
  canDeleteHostBegin: () => {
    return {
      type: actions.CAN_DELETE_HOST_BEGIN,
    };
  },
  canDeleteHostSuccess: (data) => {
    return {
      type: actions.CAN_DELETE_HOST_SUCCESS,
      data,
    };
  },
  canDeleteHostErr: (err) => {
    return {
      type: actions.CAN_DELETE_HOST_ERR,
      err,
    };
  },
};
export default actions;
