import actions from './actions';

const { UPLOAD_FILE_BEGIN, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_ERR, DOWNLOAD_FILE_BEGIN, DOWNLOAD_FILE_SUCCESS, DOWNLOAD_FILE_ERR, DOWNLOAD_RENTAL_PDF_BEGIN, DOWNLOAD_RENTAL_PDF_ERR, DOWNLOAD_RENTAL_PDF_SUCCESS } = actions;

const initState = {
  list: null,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const uploadFileReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPLOAD_FILE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case UPLOAD_FILE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DOWNLOAD_FILE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case DOWNLOAD_FILE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DOWNLOAD_RENTAL_PDF_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DOWNLOAD_RENTAL_PDF_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case DOWNLOAD_RENTAL_PDF_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default uploadFileReducer;
