import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import HttpStatus from '../../utility/status.enum';

const { hostListingBegin, hostListingSuccess, hostListingErr, 
  createHostBegin, createHostSuccess, createHostErr, getHostBegin, getHostSuccess, getHostErr, hostDeleteBegin, hostDeleteSuccess, hostDeleteErr, fetchCoverageBegin, fetchCoverageSuccess, fetchCoverageErr, canTurnOffBegin, canTurnOffSuccess, canTurnOffErr, canDeleteHostBegin, canDeleteHostSuccess, canDeleteHostErr
} = actions;

const HostListingAPI = `/admin/host-list`;
const CreateOrUpdateHostAPI = `/admin/add-edit-host`;
const GetHostDetail = '/admin/host/';
const DeleteHostAPI = '/admin/delete-user/';
const ChangePasswordAPIName = 'change-password';
const checkListingConfigBeforeOffAPI = '/admin/check-listing-config';

const fetchCoverageAPI = '/admin/partner-coverage';
const checkForDeleteHostAPI = '/admin/check-host-deletion/';

const getPartnerCoverage = () => {
  return async (dispatch) => {
    dispatch(fetchCoverageBegin());
    try {
      const response = await DataService.get(fetchCoverageAPI);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(fetchCoverageErr(response?.data?.message));
      } else {
        dispatch(fetchCoverageSuccess(response?.data));
      }
      return response?.data;
    } catch (err) {
      dispatch(fetchCoverageErr(err));
    }
  };
};

const hostListing = (values) => {
  return async (dispatch) => {
    dispatch(hostListingBegin());
    try {
      const response = await DataService.post(HostListingAPI, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(hostListingErr(response?.data?.message));
      } else {
        dispatch(hostListingSuccess(response?.data));
      }
      if(values?.is_filter === 1){
        return response?.data;
      }
      return response?.data?.result;
    } catch (err) {
      dispatch(hostListingErr(err));
    }
  };
};

const createHost = (values, callback) => {
  return async (dispatch) => {
    dispatch(createHostBegin());
    try {
      const response = await DataService.post(CreateOrUpdateHostAPI, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(createHostErr(response?.data?.message));
      } else {
        dispatch(createHostSuccess(response?.data));
      }
      return response?.data;
    } catch (err) {
      dispatch(createHostErr(err));
    }
  };
};

const hostDeleteData = (values) => {
  return async (dispatch) => {
    dispatch(hostDeleteBegin());
    try {
      const response = await DataService.delete(DeleteHostAPI + values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(hostDeleteErr(response?.data?.message));
      } else {
        dispatch(hostDeleteSuccess(response?.data));
      }
      return response?.data;
    } catch (err) {
      dispatch(hostDeleteErr(err));
    }
  };
};

const gethostDetail = (values) => {
  return async (dispatch) => {
    dispatch(getHostBegin());
    try {
      const response = await DataService.get(GetHostDetail + values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(getHostErr(response?.data?.message));
      } else {
        dispatch(getHostSuccess(response?.data));
      }
      return response?.data;
    } catch (err) {
      dispatch(getHostErr(err));
    }
  };
};

const changePassword = (values) => {
  return async (dispatch) => {
    dispatch(createHostBegin());
    try {
      const response = await DataService.post(ChangePasswordAPIName, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(createHostErr(response?.data?.message));
      } else {
        dispatch(createHostSuccess(response?.data));
      }
      return response.data;
    } catch (err) {
      dispatch(createHostErr(err));
    }
  };
};

const checkingListingBeforeOff = (values) => {
  return async (dispatch) => {
    dispatch(canTurnOffBegin());
    try {
      const response = await DataService.post(checkListingConfigBeforeOffAPI, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(canTurnOffErr(response?.data?.message));
      } else {
        dispatch(canTurnOffSuccess(response?.data));
      }
      return response?.data;
    } catch (err) {
      dispatch(canTurnOffErr(err));
    }
  };
};

const checkCanWeDeleteHost = (values) => {
  return async (dispatch) => {
    dispatch(canDeleteHostBegin());
    try {
      const response = await DataService.get(checkForDeleteHostAPI + values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(canDeleteHostErr(response?.data?.message));
      } else {
        dispatch(canDeleteHostSuccess(response?.data));
      }
      return response?.data;
    } catch (err) {
      dispatch(canDeleteHostErr(err));
    }
  };
};

export { hostListing, createHost,  hostDeleteData, gethostDetail, changePassword, getPartnerCoverage, checkingListingBeforeOff, checkCanWeDeleteHost };
