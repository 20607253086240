import actions from './actions';

const {
  WAIVER_PAYOUT_LIST_BEGIN,
  WAIVER_PAYOUT_LIST_SUCCESS,
  WAIVER_PAYOUT_LIST_ERR,
  WAIVER_PAYOUT_DETAIL_BEGIN,
  WAIVER_PAYOUT_DETAIL_SUCCESS,
  WAIVER_PAYOUT_DETAIL_ERR,
  PARTNER_COVERAGE_LIST_BEGIN,
  PARTNER_COVERAGE_LIST_SUCCESS,
  PARTNER_COVERAGE_LIST_ERR,
  STRIPE_PAYMENT_REFUND_BEGIN,
  STRIPE_PAYMENT_REFUND_SUCCESS,
  STRIPE_PAYMENT_REFUND_ERR,
} = actions;

const initState = {
  list: null,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const waiverPayoutReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case PARTNER_COVERAGE_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PARTNER_COVERAGE_LIST_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case PARTNER_COVERAGE_LIST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case WAIVER_PAYOUT_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case WAIVER_PAYOUT_LIST_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case WAIVER_PAYOUT_LIST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case WAIVER_PAYOUT_DETAIL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case WAIVER_PAYOUT_DETAIL_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case WAIVER_PAYOUT_DETAIL_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case STRIPE_PAYMENT_REFUND_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case STRIPE_PAYMENT_REFUND_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case STRIPE_PAYMENT_REFUND_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default waiverPayoutReducer;
