import { useCallback, useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Input, Modal } from 'antd';
import { Main, CardToolbox, BasicFormWrapper } from '../../styled.js';
import { Button } from '../../../components/buttons/buttons.js';
// import { AddUser } from './style.js';
import HttpStatus from '../../../utility/status.enum.js';
// import { Link } from 'react-router-dom';
import Notification from '../../../utility/notification.js';
import Spinner from '../../common/Spinner.js';
//import { changePassword } from '../../../redux/manageHost/actionCreator';
import Heading from '../../../components/heading/heading';
import UilCamera from '@iconscout/react-unicons/icons/uil-camera';
import { getProfile } from '../../../redux/userProfile/actionCreator';
import { uploadFile } from '../../../redux/uploadFile/actionCreator';
import CancelImg from '../../../static/img/Search-cancel.svg';
import { createHost } from '../../../redux/manageHost/actionCreator'; // for update profile using same
import Functions from '../../../utility/functions';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

function UpdateProfile({ updateProfileModal, hideUpdateProfileModal,handleProfileInputChange,handleImageClearChange }) {

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [pageTitle, setPageTitle] = useState('Update Profile');
  const [buttonName, setButtonName] = useState('Update');
  
  const [loginUserSlug, setLoginUserSlug] = useState('');
  const isLoading = useSelector((state) => state.Host.loading);
  const uploadFileLoading = useSelector((state) => state.UploadFile.loading);
  const [spinnerLoader, setSpinnerLoader] = useState(false);

  const [profilePreviewImage, setProfilePreviewImage] = useState('');
  const [profileLogo, setProfileLogo] = useState(null);
  const [showProfilImageValidation, setProfileImageValidation] = useState(false);
  
  const [updateProfileForm] = Form.useForm();
  const [isShowNumber, setShowPhoneNumber] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState("");
  const [loginUserRoleID, setLoginUserRoleID] = useState("");
  
  useEffect(() => {
      const getProfileFunction = async () => {
        try {
          setProfileImageValidation(false);
          setSpinnerLoader(true);
          const profileResponse = await dispatch(
            getProfile('', (response) => {
              return response;
            }),
          );
          if (profileResponse?.status !== HttpStatus.HTTP_OK) {
            // Show Error Alert
            setSpinnerLoader(false);
          } else {
            setLoginUserRoleID((profileResponse?.result.role_id));
            setLoginUserSlug(profileResponse?.result?.slug);
            setProfilePreviewImage(profileResponse?.result?.image);
            const fieldsValue = {};
            if (profileResponse?.result?.name) {
                fieldsValue.name = profileResponse.result.name;
            }
            if (profileResponse?.result?.display_name) {
                fieldsValue.display_name = profileResponse.result.display_name;
            }
            if (profileResponse?.result?.phone_number) {
              setPhone(profileResponse.result.phone_number);
            }
            if (profileResponse?.result?.email) {
              setEmail(profileResponse.result.email);
            }
            if (profileResponse?.result?.company_name) {
                fieldsValue.company_name = profileResponse.result.company_name;
            }
            updateProfileForm.setFieldsValue(fieldsValue);            
            //if(profileResponse?.result?.role_id === 3){
              setShowPhoneNumber(true);
            //}
            setSpinnerLoader(false);
          }
        } catch (error) {
          // console.error('Error fetching data:', error);
        }
      };
      if(updateProfileModal){
        getProfileFunction();
      }
  }, [updateProfileModal]);


  const handleSubmit = useCallback(
    (values) => {
      if(!profilePreviewImage){
        return false;
      }
      setSpinnerLoader(true);
      let slug = loginUserSlug;
      let is_profile_action = 1;
      let requestData = { ...values, slug, is_profile_action, phone_number:phone};

      if (profileLogo) {
        const image = profileLogo;
        requestData = { ...requestData, image };
      }
      const updateProfile = async () => {
        try {
          const updateProfileResponse = await dispatch(
            createHost(requestData, (response) => {
              return response;
            }),
          );
          if (updateProfileResponse?.status !== HttpStatus.HTTP_OK) {
            Notification.addNotificationError(updateProfileResponse.message);
          } else {
            Notification.addNotificationSuccess(updateProfileResponse.message);
          }
          window.location.reload();
          setSpinnerLoader(false);
        } catch (error) {
          // console.error('Error fetching data:', error);
        }
      }
      updateProfile();
    }
  );

  // Start Image Upload Functionality
  const handleProfileImageChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      try {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];
        if (!allowedTypes.includes(selectedFile.type)) {
            Notification.addNotificationError("Unsupported image type. Please upload an image in JPG, PNG, GIF, BMP, or WebP format.");
            return;
        }
        const compressedFile = await Functions.compressImage(selectedFile);
        setSpinnerLoader(true);
        const formData = new FormData();
        formData.append('imageType', 1);
        formData.append('file', compressedFile);
        const uploadFileResponse = await dispatch(uploadFile(formData));
        setSpinnerLoader(false);
        if (uploadFileResponse?.status === HttpStatus.HTTP_OK) {
          const fileName = uploadFileResponse?.result?.fileName;
          setProfileLogo(fileName);
          const reader = new FileReader();
          reader.onload = () => {
            setProfilePreviewImage(reader.result);
          };
          reader.readAsDataURL(compressedFile);
          setProfileImageValidation(false);
          handleProfileInputChange()
        } else {
          // console.error('Error uploading file');
        }
      } catch (error) {
        // console.error('Error:', error);
      }
    }
  };
  const deleteProfilePreviewImage = (e) => {
    setProfilePreviewImage('');
    setProfileLogo(null);
    setProfileImageValidation(true);
    handleImageClearChange()
  };
  const checkValidation = () =>{
    if(!profilePreviewImage){
      setProfileImageValidation(true);      
    }
    setTimeout(() => {
      const errorElements = document.querySelectorAll(".ant-form-item-has-error");
      if (errorElements.length > 0) {
          const firstErrorElement = errorElements[0] ?? 0;
          firstErrorElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "start"
          });
      }
    }, 300);
  } 

  // const handleInputChange = () => {
  //   handleIsDirtyChange(true);
  // };
  const handlePhoneChange = (value) => {
    const phoneWithoutCode = value.replace(/^\+?\d{1,4}/, "").trim(); 
    if (phoneWithoutCode === "") {
      setPhone("");
    } else {
      setPhone(value);
    }
  };

  return (
    <>
      <Modal
        title={pageTitle}
        visible={updateProfileModal}
        className='update_profile_modal'
        footer={null}
        onCancel={hideUpdateProfileModal}
        key={2}
        maskClosable={false}
      >
        <Spinner loader={spinnerLoader} />
        <Form form={updateProfileForm} name="update-profile" onFinish={handleSubmit} onValuesChange={handleProfileInputChange}>
          <>
            <BasicFormWrapper>
              <Row gutter={15} className='justify-content-center mt-2'>
                <Col xs={12}>
                  <div className='project-modal'>
                    <div className='upload-section profile_update_section'>
                      <figure className="photo-upload align-start-v justify-content-center">
                        <figcaption>
                          <div className="info">
                            <Heading as="h4" className="required-label text-center" ><span className='status error require image_label'></span> Image </Heading>
                          </div>
                          {/* <span className="required-label">Image * </span> */}
                          <img className={profilePreviewImage ? 'user_profile_image' : 'user_profile_image'} src={profilePreviewImage || require('../../../static/img/avatar/profileImage.png')} alt="Preview" />
                          <label htmlFor="fileProfileInput" className="btn-upload">
                            <div className={`ant-upload-select ${showProfilImageValidation ? 'ant-upload-img-error' : ''}`}>
                              <span className="btn-upload"><UilCamera /></span>
                            </div>
                            <input
                              type="file"
                              id="fileProfileInput"
                              accept="image/*"
                              className="upload_img_input"
                              onChange={handleProfileImageChange}
                              onClick={(e)=> {e.currentTarget.value = null}}
                            />
                          </label>
                        </figcaption>
                      </figure>
                      {profileLogo && <span onClick={() => deleteProfilePreviewImage('')} className='status error'><img src={CancelImg} /></span>}
                    </div>
                  </div>
                  {
                        showProfilImageValidation  && (
                        <div className='showImageError host text-center' >
                          <div class="ant-form-item-has-error ant-form-item-explain-error">Please select image</div>
                        </div>
                      )}
                </Col>
                <Col xs={24}>
                  <div className="project-modal">
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter name',
                        },
                      ]}
                    >
                      <Input placeholder="Enter Name" tabIndex="1" />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24}>
                  <div className="project-modal">
                    <Form.Item
                      label="Display Name"
                      name="display_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please display name',
                        },
                      ]}
                    >
                      <Input placeholder="Enter Display Name" tabIndex="2" />
                    </Form.Item>
                  </div>
                </Col>
                {loginUserRoleID === 3 && (
                  <Col xs={24}>
                  <div className="project-modal">
                    <Form.Item
                      label="Company Name"
                      name="company_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please company name',
                        },
                      ]}
                      >
                      <Input placeholder="Enter Company Name" tabIndex="2" />
                    </Form.Item>
                  </div>
                </Col>
                  )}
                <Col xs={24}>
                  <div className="project-modal">
                    <Form.Item
                      label="Email"
                    >
                      <Input placeholder="Enter email" tabIndex="3" className='readonlyInput' value={email} readOnly/>
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24}>
                <div className="project-modal country-code-custom_box">
                      <Form.Item
                            label="Phone Number"
                            //name="phone_number"
                            >
                        <PhoneInput
                          country={"us"} // default country
                          value={phone}
                          onChange={handlePhoneChange}
                          inputProps={{ 
                            name: "phone",
                             className: "ant-input",
                          }}
                          placeholder='Enter phone number' 
                          />
                  </Form.Item>
                    </div>
                </Col>
              </Row>
              <Row gutter={15} className="mt-20">
                <Col xs={24}>
                  <Button htmlType="submit" size="default" shape="circle" type="dark" key="submit" className="w-100" onClick={checkValidation}>
                    {isLoading || uploadFileLoading ? 'Loading...' : buttonName}
                  </Button>
                </Col>
              </Row>
            </BasicFormWrapper>
          </>
        </Form>
      </Modal>
    </>
  );
}

export default UpdateProfile;
