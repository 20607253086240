import actions from "./actions";

const {
    PARTNER_LIST_BEGIN,
    PARTNER_LIST_SUCCESS,
    PARTNER_LIST_ERR,
  
    PARTNER_CREATE_UPDATE_BEGIN,
    PARTNER_CREATE_UPDATE_SUCCESS,
    PARTNER_CREATE_UPDATE_ERR,
  
    GET_PARTNER_BEGIN,
    GET_PARTNER_SUCCESS,
    GET_PARTNER_ERR,
  
    PARTNER_DELETE_BEGIN,
    PARTNER_DELETE_SUCCESS,
    PARTNER_DELETE_ERR,
} = actions

const initState = {
    list: null,
    loading: false,
    error: null,
}

/**
 *
 * @todo impure state mutation/explaination
 */
const PartnerReducer = (state = initState, action) => {
    const { type, data, err } = action;
    switch (type) {
      case PARTNER_LIST_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case PARTNER_LIST_SUCCESS:
        return {
          ...state,
          list: data,
          loading: false,
        };
      case PARTNER_LIST_ERR:
        return {
          ...state,
          error: err,
          loading: false,
        };
      case GET_PARTNER_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case GET_PARTNER_SUCCESS:
        return {
          ...state,
          list: data,
          loading: false,
        };
      case GET_PARTNER_ERR:
        return {
          ...state,
          error: err,
          loading: false,
        };
      case PARTNER_DELETE_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case PARTNER_DELETE_SUCCESS:
        return {
          ...state,
          list: data,
          loading: false,
        };
      case PARTNER_DELETE_ERR:
        return {
          ...state,
          error: err,
          loading: false,
        };
      case PARTNER_CREATE_UPDATE_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case PARTNER_CREATE_UPDATE_SUCCESS:
        return {
          ...state,
          list: data,
          loading: false,
        };
      case PARTNER_CREATE_UPDATE_ERR:
        return {
          ...state,
          error: err,
          loading: false,
        };
      default:
        return state;
    }
  };
  export default PartnerReducer;
  