import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import HttpStatus from '../../utility/status.enum';

const { uploadFileBegin, uploadFileSuccess, uploadFileErr, uploadPdfBegin, uploadPdfSuccess, uploadPdfErr,downloadFileBegin, downloadFileErr, dowloadRentalPDFSuccess, dowloadRentalPDFErr, dowloadRentalPDFBegin } = actions;

const uploadFileAPIName ='upload-image';
const downloadFileAPIName ='download-file';
const uploadPdfAPIName = 'upload-file';
const downloadRentalAgreement ='admin/download-rental-agreement';

const uploadFile = (values) => {
  return async (dispatch) => {
    dispatch(uploadFileBegin());
    try {
      const response = await DataService.post(uploadFileAPIName, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(uploadFileErr(response?.data?.message));
        return response.data;
      } else {
        dispatch(uploadFileSuccess(response?.data));
        return response.data;
      }
    } catch (err) {
      dispatch(uploadFileErr(err));
    }
  };
};

const uploadPdf = (values) => {
  return async (dispatch) => {
    dispatch(uploadPdfBegin());
    try {
      const response = await DataService.post(uploadPdfAPIName, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(uploadPdfErr(response?.data?.message));
        return response.data;
      } else {
        dispatch(uploadPdfSuccess(response?.data));
        return response.data;
      }
    } catch (err) {
      dispatch(uploadPdfErr(err));
    }
  };
};

const downloadFile = (values) => {
  return async (dispatch) => {
    dispatch(downloadFileBegin());
    try {
      let endPoint = `${process.env.REACT_APP_API_ENDPOINT}` + downloadFileAPIName + values;
      window.location.href = endPoint;
      // const response = await DataService.get(downloadFileAPIName + values);
      // window.location.href = (process.env.REACT_APP_API_ENDPOINT+response);
      // return response;
      // if (response?.data?.status !== HttpStatus.HTTP_OK) {
      //   dispatch(downloadFileErr(response?.data?.message));
      //   return response.data;
      // } else {
      //   dispatch(downloadFileSuccess(response?.data));
      //   return response;
      // }
    } catch (err) {
      dispatch(downloadFileErr(err));
    }
  };
};

const downloadRentalPDF = (values) => {
  return async (dispatch) => {
    dispatch(dowloadRentalPDFBegin());
    try {
      const response = await DataService.post(downloadRentalAgreement, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(dowloadRentalPDFErr(response?.data?.message));
        return response.data;
      } else {
        dispatch(dowloadRentalPDFSuccess(response?.data));
        return response;
      }
    } catch (err) {
      dispatch(dowloadRentalPDFErr(err));
    }
  };
};

export { uploadFile,uploadPdf,downloadFile, downloadRentalPDF };
