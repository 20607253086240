import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import React, { useEffect, useState, lazy, useRef, useContext } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import store from './redux/store';

import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import { checkPermission } from '../src/utility/utility';
import WebSocketContext from './utility/WebSocketContext';
import actions from './redux/manageMessage/actions';
import { getUnreadMessage } from './redux/manageMessage/actionCreator';
import HttpStatus from './utility/status.enum';
// import ResetPassword from './container/profile/authentication/overview/ResetPassword';

global.backEndPrefix = ``;
global.dateFormat = `MM/dd/yy`;
global.perPageRecord = 30;
global.checkPermission = checkPermission;
global.unsaveMessage = 'You have unsaved changes. Are you sure you want to leave?';
global.isDirtyGlobal = false;
global.adminLoginRoleID = 1;
global.hostLoginRoleID = 3;
global.providerLoginRoleID = 5;
global.isChatStatus = false;  // chat enable or disable status

const NotFound = lazy(() => import('./container/pages/404'));

const { themeColor } = config;

function ProviderConfig() {
  const { storeGuestList, storeGuestLastPage, storeSearchText, storeSelectedThread } = actions;
  const { handleWebSocketEvent, setNewMessageReceived } = useContext(WebSocketContext);
  const { backEndPrefix } = global;
  const dispatch = useDispatch();

  const socketRef = useRef(null);
  const { rtl, isLoggedIn, topMenu, mainContent } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
    };
  });

  const [path, setPath] = useState(window.location.pathname);
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => {
      unmounted = true;
    };
  }, [setPath]);
  useEffect(() => {
    const initializeWebSocket = () => {
      const getUser = JSON.parse(localStorage.getItem('user'));
      if (getUser) {
        let connectionString =
          '?userId=' +
          getUser?.slug +
          '&userType=' +
          getUser.role_id +
          '&systemId=' +
          localStorage.getItem('system-id');
        let socketUrl = process.env.REACT_APP_WEB_SOCKET_URL + connectionString;

        const ws = new WebSocket(socketUrl);
        socketRef.current = ws;

        const reconnectInterval = 5000; // Reconnect every 5 seconds
        let pingInterval;

        ws.onopen = () => { // WebSocket connection opened
          const timeInterval = 5 * 60 * 1000; // Every 5 minutes
          pingInterval = setInterval(() => { // WebSocket connection: ping-pong
            if (ws.readyState === WebSocket.OPEN) {
              ws.send(JSON.stringify({ type: 'ping' }));
            }
          }, timeInterval);
        };

        ws.onmessage = (event) => {
          const parsedMessage = JSON.parse(event?.data);
          if (parsedMessage.message !== 'Forbidden') {
            handleWebSocketEvent(parsedMessage, getUser?.role_id);
          }
        };

        ws.onerror = (error) => {
          console.error('WebSocket error:', error);
        };

        // WebSocket connection closed, attempting to reconnect...
        ws.onclose = () => {
          clearInterval(pingInterval);
          socketRef.current = null; // Reset WebSocket instance

          // Retry connection
          setTimeout(() => {
            if (!socketRef.current && isLoggedIn) {
              // Only attempt to reconnect if user is logged in
              initializeWebSocket();
            }
          }, reconnectInterval);
        };

        return () => {
          clearInterval(pingInterval);
          ws.close();
        };
      }
    };

    if (isLoggedIn && !socketRef.current) {
      initializeWebSocket();
    } else if (!isLoggedIn) {
      // Ensure the WebSocket is closed if the user is not logged in
      if (socketRef.current) {
        socketRef.current.close();
      }
    }
  }, [isLoggedIn, handleWebSocketEvent]);

  const [loginUser, setUserLoginUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    const getUser = JSON.parse(localStorage.getItem('user'));
    if (getUser) {
      if(getUser.role_id === global.adminLoginRoleID){    
       global.isChatStatus = true;  // setting chat enable status for admin
      }
      setUserLoginUser(getUser);
      // redux cleared for chatApp
      dispatch(storeGuestList(null));
      dispatch(storeGuestLastPage(0));
      dispatch(storeSearchText(''));
      dispatch(storeSelectedThread(null));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const unreadMessage = async () => {
      try {
        const unreadIndicator = await dispatch(getUnreadMessage());
        if (unreadIndicator.status === HttpStatus.HTTP_OK) {
          if(unreadIndicator?.result?.unread_count > 0){
            setNewMessageReceived(1)
          }
        }
      } catch (error) {}
    };
    if(isLoggedIn && [global.adminLoginRoleID, global.hostLoginRoleID].includes(loginUser?.role_id)){
      unreadMessage();
    }
  }, [isLoggedIn])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const getUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser) {
          if (loginUser?.role_id !== getUser?.role_id) {
            if ([1, 3].includes(getUser?.role_id)) {
              if (getUser) {
                setUserLoginUser(getUser);
              }
              window.location.href = `${backEndPrefix}/reservation`;
            } else if ([5].includes(getUser?.role_id)) {
              if (getUser) {
                setUserLoginUser(getUser);
              }
              window.location.href = `${backEndPrefix}/waiver-report`;
            } else {
              if (getUser) {
                setUserLoginUser(getUser);
              }
              window.location.reload();
            }
          } else {
            //console.log("User role is the same.");
          }
        } else {
          if (getUser) {
            setUserLoginUser(getUser);
          }
        }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [loginUser]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
        <>
          <Router basename={process.env.PUBLIC_URL}>
            {!isLoggedIn ? (
              <Routes>
                <Route path="/*" element={<Auth />} />{' '}
              </Routes>
            ) : (
              <Routes>
                <Route path="/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            )}
            {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
              <Routes>
                <Route path="/" element={<Navigate to="/" />} />
              </Routes>
            )}
            {/* <Routes>
                 <Route path="/reset-passowrd" element={<ResetPassword />} />
            </Routes> */}
          </Router>
        </>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
