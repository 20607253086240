const actions = {
  RESERVATION_LIST_BEGIN: 'RESERVATION_LIST_BEGIN',
  RESERVATION_LIST_SUCCESS: 'RESERVATION_LIST_SUCCESS',
  RESERVATION_LIST_ERR: 'RESERVATION_LIST_ERR',

  RESERVATION_CLAIM_BEGIN: 'RESERVATION_CLAIM_BEGIN',
  RESERVATION_CLAIM_SUCCESS: 'RESERVATION_CLAIM_SUCCESS',
  RESERVATION_CLAIM_ERR: 'RESERVATION_LIST_ERR',

  REPORT_DAMAGE_BEGIN: 'REPORT_DAMAGE_BEGIN',
  REPORT_DAMAGE_SUCCESS: 'REPORT_DAMAGE_SUCCESS',
  REPORT_DAMAGE_ERR: 'REPORT_DAMAGE_ERR',
  
  reservationListBegin: () => {
    return {
      type: actions.RESERVATION_LIST_BEGIN,
    };
  },
  reservationListSuccess: (data) => {
    return {
      type: actions.RESERVATION_LIST_SUCCESS,
      data,
    };
  },

  reservationListErr: (err) => {
    return {
      type: actions.RESERVATION_LIST_ERR,
      err,
    };
  },

  reservationClaimBegin: () => {
    return {
      type: actions.RESERVATION_CLAIM_BEGIN,
    };
  },
  reservationClaimSuccess: (data) => {
    return {
      type: actions.RESERVATION_CLAIM_SUCCESS,
      data,
    };
  },
  reservationClaimErr: (err) => {
    return {
      type: actions.RESERVATION_CLAIM_ERR,
      err,
    };
  },
  reportDamageBegin: () => {
    return {
      type: actions.REPORT_DAMAGE_BEGIN,
    };
  },
  reportDamageSuccess: (data) => {
    return {
      type: actions.REPORT_DAMAGE_SUCCESS,
      data,
    };
  },
  reportDamageErr: (err) => {
    return {
      type: actions.REPORT_DAMAGE_ERR,
      err,
    };
  },
};
export default actions;
