const actions = {
  UPSELL_LIST_BEGIN: 'UPSELL_LIST_BEGIN',
  UPSELL_LIST_SUCCESS: 'UPSELL_LIST_SUCCESS',
  UPSELL_LIST_ERR: 'UPSELL_LIST_ERR',

  upSellListingBegin: () => {
    return {
      type: actions.UPSELL_LIST_BEGIN,
    };
  },
  upSellListingSuccess: (data) => {
    return {
      type: actions.UPSELL_LIST_SUCCESS,
      data,
    };
  },
  upSellListingErr: (err) => {
    return {
      type: actions.UPSELL_LIST_ERR,
      err,
    };
  },
};
export default actions;
