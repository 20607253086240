import React, { createContext, useState, useRef } from 'react';

const WebSocketContext = createContext();  // Create the context

export const WebSocketProvider = ({ children }) => {
  const socketData = useRef([]);
  const [newMessageReceived, setNewMessageReceived] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const handleWebSocketEvent = (data, role_id) => {
    console.log("Web Socket Context ", data)
    if(data === -1){
      socketData.current = [];
    } else {
      socketData.current = [...socketData.current, data];
      const unreadRecognizer = (role_id === 1 && data?.message_from === 4) || (role_id === 3 && data?.message_from === 3)
      if(!unreadRecognizer) setNewMessageReceived(1)
    }
    setRefresh(pre => !pre)
  };

  return (
    <WebSocketContext.Provider value={{ socketData, newMessageReceived, setNewMessageReceived, handleWebSocketEvent }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketContext;  // Export the context itself
