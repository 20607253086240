import actions from './actions';

const { GET_PROFILE_BEGIN, GET_PROFILE_SUCCESS, GET_PROFILE_ERR } = actions;

const initState = {
  list: null,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const profileReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_PROFILE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_PROFILE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default profileReducer;
