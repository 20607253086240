import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import HttpStatus from '../../utility/status.enum';
import Functions from '../../utility/functions';

const LoginAPIName = `/login`;
const LogoutAPIName = `/logout`;
const ForgotPasswordAPIName = `/forgot-password`;
const ResetPasswordAPIName = `/reset-password`;

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  forgotPasswordBegin,
  forgotPasswordErr,
  forgotPasswordSuccess,
  resetPasswordBegin,
  resetPasswordErr,
  resetPasswordSuccess,
} = actions;

const login = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post(LoginAPIName, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(loginErr(response?.data?.message));
      } else {
        Cookies.set('access_token', response?.data?.result?.headerToken);
        Cookies.set('logedIn', true);
        localStorage.setItem('user', JSON.stringify(response?.data?.result));
        if (!localStorage.getItem('system-id')) {
          localStorage.setItem('system-id', Functions.generateSlug(20));
        }
        localStorage.setItem('permissions', JSON.stringify(response?.data?.result?.permissions));
        dispatch(loginSuccess(true));       
      }
      return response?.data;
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const authOLogin = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    Cookies.set('access_token', values.accessToken);
    Cookies.set('logedIn', true);
    dispatch(loginSuccess(true));
    callback();
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/register', values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        dispatch(loginSuccess(false));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      const response = await DataService.get(LogoutAPIName);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(logoutErr(response?.data?.message));
      } else {
        Cookies.remove('logedIn');
        Cookies.remove('access_token');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        localStorage.removeItem('system-id');
        dispatch(logoutSuccess(false));
      }
      return response?.data;
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const forgotPassword = (values, callback) => {
  return async (dispatch) => {
    dispatch(forgotPasswordBegin());
    try {
      const response = await DataService.post(ForgotPasswordAPIName, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(forgotPasswordErr(response?.data?.message));
      } else {
        dispatch(forgotPasswordSuccess(true));
      }
      return response?.data;
    } catch (err) {
      dispatch(forgotPasswordErr(err));
    }
  };
};

const resetPasswords = (values) => {
  return async (dispatch) => {
    dispatch(resetPasswordBegin());
    try {
      const response = await DataService.post(ResetPasswordAPIName, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(resetPasswordErr(response?.data?.message));
      } else {
        dispatch(resetPasswordSuccess(true));
      }
      return response?.data;
    } catch (err) {
      dispatch(resetPasswordErr(err));
    }
  };
};

export { login, authOLogin, logOut, register, forgotPassword, resetPasswords };
