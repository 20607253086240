import actions from './actions';

const { HOST_LIST_BEGIN, HOST_LIST_SUCCESS, HOST_LIST_ERR, HOST_CREATE_BEGIN, HOST_CREATE_SUCCESS, HOST_CREATE_ERR, GET_HOST_BEGIN, GET_HOST_SUCCESS, GET_HOST_ERR, HOST_DELETE_BEGIN, HOST_DELETE_SUCCESS, HOST_DELETE_ERR, FETCH_COVERAGE_BEGIN, FETCH_COVERAGE_SUCCESS, FETCH_COVERAGE_ERR } = actions;

const initState = {
  list: null,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const HostReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case HOST_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case HOST_LIST_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case HOST_LIST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case HOST_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case HOST_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case HOST_CREATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_HOST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_HOST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_HOST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case HOST_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case HOST_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case HOST_DELETE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case FETCH_COVERAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COVERAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_COVERAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default HostReducer;
