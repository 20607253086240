const actions = {
  BRAND_LIST_BEGIN: 'BRAND_LIST_BEGIN',
  BRAND_LIST_SUCCESS: 'BRAND_LIST_SUCCESS',
  BRAND_LIST_ERR: 'BRAND_LIST_ERR',

  brandListingBegin: () => {
    return {
      type: actions.BRAND_LIST_BEGIN,
    };
  },
  brandListingSuccess: (data) => {
    return {
      type: actions.BRAND_LIST_SUCCESS,
      data,
    };
  },

  brandListingErr: (err) => {
    return {
      type: actions.BRAND_LIST_ERR,
      err,
    };
  },
};
export default actions;
