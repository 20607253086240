import { notification } from 'antd';

const addNotificationSuccess = (message) => {
  notification.success({
    message: message,
  });
};
const addNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

export default { addNotificationSuccess, addNotificationError };