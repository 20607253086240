const actions = {
  LISTING_PARTNER_LIST_BEGIN: 'LISTING_PARTNER_LIST_BEGIN',
  LISTING_PARTNER_LIST_SUCCESS: 'LISTING_PARTNER_LIST_SUCCESS',
  LISTING_PARTNER_LIST_ERR: 'LISTING_PARTNER_LIST_ERR',

  listingPartnerListingBegin: () => {
    return {
      type: actions.LISTING_PARTNER_LIST_BEGIN,
    };
  },
  listingPartnerListingSuccess: (data) => {
    return {
      type: actions.LISTING_PARTNER_LIST_SUCCESS,
      data,
    };
  },

  listingPartnerListingErr: (err) => {
    return {
      type: actions.LISTING_PARTNER_LIST_ERR,
      err,
    };
  },
};
export default actions;