import actions from './actions';

const {
  ITEM_LIST_BEGIN,
  ITEM_LIST_SUCCESS,
  ITEM_LIST_ERR,
  SET_GUEST_LIST,
  SET_GUEST_LAST_PAGE,
  SET_SEARCH_TEXT,
  SET_SELECTED_THREAD,
} = actions;

const initState = {
  list: null,
  loading: false,
  error: null,
  guestList: null,
  guestLastPage: 0,
  searchText: '',
  savedSelectedThread: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const threadListReducer = (state = initState, action) => {
  const { type, data, err, payload } = action;
  switch (type) {
    case ITEM_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ITEM_LIST_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case ITEM_LIST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SET_GUEST_LIST:
      return {
        ...state,
        guestList: payload,
      };
    case SET_GUEST_LAST_PAGE:
      return {
        ...state,
        guestLastPage: payload,
      };
    case SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: payload,
      };
    case SET_SELECTED_THREAD:
      return {
        ...state,
        savedSelectedThread: payload,
      };
    default:
      return state;
  }
};
export default threadListReducer;
