const actions = {
  PMS_BEGIN: 'PMS_BEGIN',
  PMS_SUCCESS: 'PMS_SUCCESS',
  PMS_ERR: 'PMS_ERR',

  CREATE_PMS_BEGIN: 'CREATE_PMS_BEGIN',
  CREATE_PMS_SUCCESS: 'CREATE_PMS_SUCCESS',
  CREATE_PMS_ERR: 'CREATE_PMS_ERR',

  pmsBegin: () => {
    return {
      type: actions.PMS_BEGIN,
    };
  },
  pmsSuccess: (data) => {
    return {
      type: actions.PMS_SUCCESS,
      data,
    };
  },

  pmsErr: (err) => {
    return {
      type: actions.PMS_ERR,
      err,
    };
  },

  createPmsBegin: () => {
    return {
      type: actions.CREATE_PMS_BEGIN,
    };
  },
  createPmsSuccess: (data) => {
    return {
      type: actions.CREATE_PMS_SUCCESS,
      data,
    };
  },

  createPmsErr: (err) => {
    return {
      type: actions.CREATE_PMS_ERR,
      err,
    };
  },
};
export default actions;
