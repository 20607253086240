import { Avatar, Form } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import HttpStatus from '../../../utility/status.enum';
import chagePasswordIcon from '../../../static/img/chagePassword.svg';
import pmsIcon from '../../../static/img/pms-icon.svg';
import profileicon from '../../../static/img/avatar/profile-icon.svg';
import Changepassword from '../../../container/pages/changePassword/ChangePassword';
import UpdateProfile from '../../../container/profile/updateProfile/UpdateProfile';
import { logOut } from '../../../redux/authentication/actionCreator';

import { getProfile } from '../../../redux/userProfile/actionCreator';
import signouticon from '../../../static/img/sign-out-icon.svg';
import Notification from '../../../utility/notification.js';
import stripeIcon from '../../../static/img/stripe.svg';
import { stripeInvitation } from '../../../redux/manageStripe/actionCreator';
import Spinner from '../../../../src/container/common/Spinner';
import UserAvatar from '../../../static/img/user-avtar.webp';

const AuthInfo = React.memo(() => {
  const { unsaveMessage, backEndPrefix } = global;
  const dispatch = useDispatch();
  const [loginUser, setLoginUser] = useState([]);
  const isMounted = useRef(true);
  const navigate = useNavigate();
  const [spinnerLoader, setSpinnerLoader] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      const getProfileFunction = async () => {
        try {
          const profileResponse = await dispatch(
            getProfile('', (response) => {
              return response;
            }),
          );
          if (profileResponse?.status !== HttpStatus.HTTP_OK) {
            // Show Error Alert
          } else {
            global.isChatStatus= profileResponse?.result?.is_chat_enable === 1 || profileResponse?.result?.role_id === global.adminLoginRoleID;   // chat enable or disable status
            setLoginUser(profileResponse?.result)
          }
        } catch (error) {
        }
      };
      getProfileFunction();
    }
  }, []);

  const [changepasswordModal, setChangepasswordModal] = useState(false);
  const [isChangePasswordDirty, setChangePasswordDirty] = useState(false);
  const handlechangepasswordModal = () => {
    setChangepasswordModal(true);
  };

  const handleChangePasswordInputChange = () => {
    setChangePasswordDirty(true);
  };

  const hidechangepasswordModal = () => {
    if (isChangePasswordDirty) {
      const confirmCancel = window.confirm(unsaveMessage);
      if (confirmCancel) {
        setChangePasswordDirty(false);
        setChangepasswordModal(false);
      }
    } else {
      setChangepasswordModal(false);
    }
  };

  const [updateProfileModal, setUpdateProfileModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const handlecupdateProfileModal = () => {
    setUpdateProfileModal(true);
  };
  const handleProfileInputChange = () => {
    setIsDirty(true);
  };
  const handleImageClearChange = () => {
    setIsDirty(false);
  };
  
  const hideUpdateProfileModal = () => {
    if (isDirty) {
      const confirmCancel = window.confirm(unsaveMessage);
      if (confirmCancel) {
        setIsDirty(false);
        setUpdateProfileModal(false);
      }
    } else {
      setUpdateProfileModal(false);
    }
  };

  const [state, setState] = useState({
    flag: 'en',
  });

  const SignOut = (e) => {
    e.preventDefault();
    const logoutFunction = async () => {
      try {
        const logoutResponse = await dispatch(
          logOut(),
        );
        if (logoutResponse?.status !== HttpStatus.HTTP_OK) {
          Notification.addNotificationError(logoutResponse.message);
        } else {
          Notification.addNotificationSuccess(logoutResponse.message);
          navigate('/')
        }
      } catch (error) {
      }
    }
    logoutFunction();

  };

   // Send Stripe Invitaion Email:
   const handleConnectStripeEvent = () => {
    if (loginUser.slug) {
      setSpinnerLoader(true);
      const slug = loginUser.slug;
      const is_send_email = 2; // Email will not send
      const requestData = { slug,is_send_email};
      const stripeInvitationLinkGetFunction = async () => {
        try {
          const stripeInvitationResponse = await dispatch(
            stripeInvitation(requestData, (response) => {
              return response;
            }),
          );
          if (stripeInvitationResponse?.status === HttpStatus.HTTP_OK) {           
           const stripeLink = stripeInvitationResponse?.result?.stripe_invitation_link;
           window.open(stripeLink, '_blank');
          } else {
            Notification.addNotificationError(stripeInvitationResponse.message);
          }
          setSpinnerLoader(false);
        } catch (error) {
          // console.error('Error fetching data:', error);
        }
      };
      stripeInvitationLinkGetFunction();
    }
  }
  
  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">        
        <div style={{ width: "180px" }}>

        </div>
      </div>
    </UserDropDwon>
  );

  const handleOnCLick = (e) => {
    if(global.isDirtyGlobal){
      const confirmCancel = window.confirm(unsaveMessage);
      if (confirmCancel) {
        global.isDirtyGlobal = false;
        window.location.href = `${backEndPrefix}/pms-integration`
      } else {
        e.preventDefault();
      }
    } else {
      window.location.href = `${backEndPrefix}/pms-integration`
    } 
  }

  const [addClassDynamic, setAddClassDynamic] = useState('');
  useEffect(() => {
    let className = '';
    if (global.checkPermission('pms-integration-index') && loginUser.role_id === 3) { // This code will be execute for host
      if (loginUser.is_stripe_status === 2) { // If host connected with stripe then this class will be add
        className = 'pms-user_profile_box';
      } else {// If host is not connected with stripe then this class will be add
        className = 'payout-setting-profile-box';
      }
    } else if (loginUser.role_id === 5) { // Provider and if stripe account not link then payout menu will be show
      if (loginUser.is_stripe_status !== 2) {
        className = 'payout-menu';
      }
    }
    setAddClassDynamic(className);
  }, [loginUser]);
  return (   
    <InfoWraper className='autoprofile'>      
       <Spinner loader={spinnerLoader} />
      <div className='d-flex justify-content-center w-100 flex-column'>
        <div className={`ninjadash-nav-actions__item ninjadash-nav-actions__author d-flex align-items-center user_profile_box ${addClassDynamic}`}>
          <Link to="#" className="ninjadash-nav-action-link d-flex">
            {loginUser && loginUser.image !== "" ? (
              <Avatar src={loginUser.image} />
            ) : (
              <Avatar src={UserAvatar} />
            )}
            <div className='ml-10 admin_welcome_box'>
              <span className="welcome d-block">Welcome</span>
              <span className="ninjadash-nav-actions__author--name">{loginUser ? loginUser.name : ''}</span>
            </div>
          </Link>
          <div className={`user_profile_option ${([3, 5].includes(loginUser.role_id) && loginUser.is_stripe_status !== 2) ? 'new_menu_item_add' : ''}`}>
          {global.checkPermission('pms-integration-index') && (
              <Link className="pms-intergation-desktop settings" onClick={handleOnCLick}>
                <img src={pmsIcon} />
                <span>PMS Integration</span>
              </Link>
            )}
            {([3, 5].includes(loginUser.role_id)) && loginUser.is_stripe_status !== 2 && (
              <Link onClick={handleConnectStripeEvent} className='settings'>
                <img src={stripeIcon} />
                <span>Payout Settings</span>
              </Link>
            )}
            
            <Link to="#" className='settings' onClick={handlecupdateProfileModal}>
              <img src={profileicon} />
              <span>Update Profile</span>
            </Link>
            <Link to="#" className='settings' onClick={handlechangepasswordModal}>
              <img src={chagePasswordIcon} />
              <span>Change Password</span>
            </Link>
            <Link type='button' onClick={SignOut} className='settings'><img src={signouticon} />Sign Out</Link>
          </div>
        </div>        
      </div>      
      <UpdateProfile updateProfileModal={updateProfileModal} hideUpdateProfileModal={hideUpdateProfileModal} handleProfileInputChange={handleProfileInputChange} handleImageClearChange={handleImageClearChange} />
      <Changepassword changepasswordModal={changepasswordModal} hidechangepasswordModal={hidechangepasswordModal} handleChangePasswordInputChange={handleChangePasswordInputChange}/>
    </InfoWraper>
  );
});

export default AuthInfo;