const actions = {
    PARTNER_LIST_BEGIN: 'PARTNER_BEGIN',
    PARTNER_LIST_SUCCESS: 'PARTNER_SUCCESS',
    PARTNER_LIST_ERR: 'PARTNER_ERR',
  
    PARTNER_CREATE_UPDATE_BEGIN: 'PARTNER_CREATE_UPDATE_BEGIN',
    PARTNER_CREATE_UPDATE_SUCCESS: 'PARTNER_CREATE_UPDATE_SUCCESS',
    PARTNER_CREATE_UPDATE_ERR: 'PARTNER_CREATE_UPDATE_ERR',
  
    GET_PARTNER_BEGIN: 'GET_HOST_BEGIN',
    GET_PARTNER_SUCCESS: 'GET_HOST_SUCCESS',
    GET_PARTNER_ERR: 'GET_HOST_ERR',
  
    PARTNER_DELETE_BEGIN: 'PARTNER_DELETE_BEGIN',
    PARTNER_DELETE_SUCCESS: 'PARTNER_DELETE_SUCCESS',
    PARTNER_DELETE_ERR: 'PARTNER_DELETE_ERR',

    CAN_DELETE_COVERAGE_BEGIN: 'CAN_DELETE_COVERAGE_BEGIN',
    CAN_DELETE_COVERAGE_SUCCESS: 'CAN_DELETE_COVERAGE_SUCCESS',
    CAN_DELETE_COVERAGE_ERR: 'CAN_DELETE_COVERAGE_ERR',

    CAN_DELETE_PARTNER_BEGIN: 'CAN_DELETE_PARTNER_BEGIN',
    CAN_DELETE_PARTNER_SUCCESS: 'CAN_DELETE_PARTNER_SUCCESS',
    CAN_DELETE_PARTNER_ERR: 'CAN_DELETE_PARTNER_ERR',
  
    partnerBegin: () => {
      return {
        type: actions.PARTNER_LIST_BEGIN,
      };
    },
    partnerSuccess: (data) => {
      return {
        type: actions.PARTNER_LIST_SUCCESS,
        data,
      };
    },
  
    partnerErr: (err) => {
      return {
        type: actions.PARTNER_LIST_ERR,
        err,
      };
    },
    partnerDetailBegin: () => {
      return {
        type: actions.GET_PARTNER_BEGIN,
      };
    },
    partnerDetailSuccess: (data) => {
      return {
        type: actions.GET_PARTNER_SUCCESS,
        data,
      };
    },
  
    partnerDetailErr: (err) => {
      return {
        type: actions.GET_PARTNER_ERR,
        err,
      };
    },
    partnerDeleteBegin: () => {
      return {
        type: actions.PARTNER_DELETE_BEGIN,
      };
    },
    partnerDeleteSuccess: (data) => {
      return {
        type: actions.PARTNER_DELETE_SUCCESS,
        data,
      };
    },
    partnerDeleteErr: (err) => {
      return {
        type: actions.PARTNER_DELETE_ERR,
        err,
      };
    }, 
    createOrUpdatePartnerBegin: () => {
      return {
        type: actions.PARTNER_CREATE_UPDATE_BEGIN,
      };
    },
    createOrUpdatePartnerSuccess: (data) => {
      return {
        type: actions.PARTNER_CREATE_UPDATE_SUCCESS,
        data,
      };
    },
    createOrUpdatePartnerErr: (err) => {
      return {
        type: actions.PARTNER_CREATE_UPDATE_ERR,
        err,
      };
    }, 
    canDeleteCoverageBegin: () => {
      return {
        type: actions.CAN_DELETE_COVERAGE_BEGIN,
      };
    },
    canDeleteCoverageSuccess: (data) => {
      return {
        type: actions.CAN_DELETE_COVERAGE_SUCCESS,
        data,
      };
    },
    canDeleteCoverageErr: (err) => {
      return {
        type: actions.CAN_DELETE_COVERAGE_ERR,
        err,
      };
    },
    canDeletePartnerBegin: () => {
      return {
        type: actions.CAN_DELETE_PARTNER_BEGIN,
      };
    },
    canDeletePartnerSuccess: (data) => {
      return {
        type: actions.CAN_DELETE_PARTNER_SUCCESS,
        data,
      };
    },
    canDeletePartnerErr: (err) => {
      return {
        type: actions.CAN_DELETE_PARTNER_ERR,
        err,
      };
    }
  };
  export default actions;
  