const actions = {
  STRIPE_INVITATION_BEGIN: 'STRIPE_INVITATION_BEGIN',
  STRIPE_INVITATION_SUCCESS: 'STRIPE_INVITATION_SUCCESS',
  STRIPE_INVITATION_ERR: 'STRIPE_INVITATION_ERR',

  inviteStripeBegin: () => {
    return {
      type: actions.STRIPE_INVITATION_BEGIN,
    };
  },
  inviteStripeSuccess: (data) => {
    return {
      type: actions.STRIPE_INVITATION_SUCCESS,
      data,
    };
  },

  inviteStripeErr: (err) => {
    return {
      type: actions.STRIPE_INVITATION_ERR,
      err,
    };
  },
};
export default actions;
