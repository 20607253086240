import actions from './actions';

const {
  UPSELL_LIST_BEGIN,
  UPSELL_LIST_SUCCESS,
  UPSELL_LIST_ERR,
  CHANGE_ORDER_STATUS_BEGIN,
  CHANGE_ORDER_STATUS_SUCCESS,
  CHANGE_ORDER_STATUS_ERR,
} = actions;

const initState = {
  list: null,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const upSellReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPSELL_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPSELL_LIST_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case UPSELL_LIST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CHANGE_ORDER_STATUS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case CHANGE_ORDER_STATUS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default upSellReducer;
