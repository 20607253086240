const actions = {
  UPLOAD_FILE_BEGIN: 'UPLOAD_FILE_BEGIN',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERR: 'UPLOAD_FILE_ERR',
  
  UPLOAD_PDF_BEGIN: 'UPLOAD_PDF_BEGIN',
  UPLOAD_PDF_SUCCESS: 'UPLOAD_PDF_SUCCESS',
  UPLOAD_PDF_ERR: 'UPLOAD_PDF_ERR',

  DOWNLOAD_FILE_BEGIN: 'DOWNLOAD_FILE_BEGIN',
  DOWNLOAD_FILE_SUCCESS: 'DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_ERR: 'DOWNLOAD_FILE_ERR',

  DOWNLOAD_RENTAL_PDF_BEGIN: 'DOWNLOAD_RENTAL_PDF_BEGIN',
  DOWNLOAD_RENTAL_PDF_SUCCESS: 'DOWNLOAD_RENTAL_PDF_SUCCESS',
  DOWNLOAD_RENTAL_PDF_ERR: 'DOWNLOAD_RENTAL_PDF_ERR',

  uploadPdfBegin: () => {
    return {
      type: actions.UPLOAD_PDF_BEGIN,
    };
  },

  uploadPdfSuccess: (data) => {
    return {
      type: actions.UPLOAD_PDF_SUCCESS,
      data,
    };
  },

  uploadPdfErr: (err) => {
    return {
      type: actions.UPLOAD_PDF_ERR,
      err,
    };
  },
  
  uploadFileBegin: () => {
    return {
      type: actions.UPLOAD_FILE_BEGIN,
    };
  },

  uploadFileSuccess: (data) => {
    return {
      type: actions.UPLOAD_FILE_SUCCESS,
      data,
    };
  },

  uploadFileErr: (err) => {
    return {
      type: actions.UPLOAD_FILE_ERR,
      err,
    };
  },
  downloadFileBegin: () => {
    return {
      type: actions.DOWNLOAD_FILE_BEGIN,
    };
  },

  downloadFileSuccess: (data) => {
    return {
      type: actions.DOWNLOAD_FILE_SUCCESS,
      data,
    };
  },

  downloadFileErr: (err) => {
    return {
      type: actions.DOWNLOAD_FILE_ERR,
      err,
    };
  },
  dowloadRentalPDFBegin: () => {
    return {
      type: actions.DOWNLOAD_RENTAL_PDF_BEGIN,
    };
  },
  dowloadRentalPDFSuccess: (data) => {
    return {
      type: actions.DOWNLOAD_RENTAL_PDF_SUCCESS,
      data,
    };
  },
  dowloadRentalPDFErr: (err) => {
    return {
      type: actions.DOWNLOAD_RENTAL_PDF_ERR,
      err,
    };
  },
};

export default actions;
