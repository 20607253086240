import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import HttpStatus from '../../utility/status.enum';

const { getProfileBegin, getProfileSuccess, getProfileErr } = actions;

const getProfileAPIName ='profile';

const getProfile = (values) => {
  return async (dispatch) => {
    dispatch(getProfileBegin());
    try {
      const response = await DataService.get(getProfileAPIName);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(getProfileErr(response?.data?.message));
        return response.data;
      } else {
        dispatch(getProfileSuccess(response?.data));
        return response.data;
      }
    } catch (err) {
      dispatch(getProfileErr(err));
    }
  };
};

export { getProfile };
