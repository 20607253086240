const compressImage = (file, quality = 0.7, maxWidth = 800, maxHeight = 800) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
        canvas.width = img.width * ratio;
        canvas.height = img.height * ratio;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(
          (blob) => {
            resolve(
              new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              }),
            );
          },
          file.type,
          quality,
        );
      };
      img.onerror = (error) => reject(error);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const convertUTCToLocalTime = (utcTime) => {
  const currentDate = new Date().toISOString().split('T')[0];
  const utcDate = new Date(`${currentDate}T${utcTime}Z`);

  const localTime = utcDate.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return localTime;
};

export const getCurrentUTCTime = () => {
  const now = new Date();
  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(now.getUTCMinutes()).padStart(2, '0');
  const seconds = String(now.getUTCSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

/*
const convertUTCToLocalDateTime = (utcDateTime) => {
  // Convert the UTC date-time string to a Date object
  console.log()
  const utcDate = new Date(`${utcDateTime} UTC`);

  // Format the local date as MM/DD/YY
  const localDate = utcDate.toLocaleDateString(undefined, {
    year: '2-digit', // last two digits of the year
    month: '2-digit',
    day: '2-digit',
  });

  // Format the local time as HH:MM AM/PM
  const localTime = utcDate.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return `${localDate} ${localTime}`;
};
*/


// Use ISO 8601 Format:
const convertUTCToLocalDateTime = (utcDateTime) => {
   const isoFormatDate = `${utcDateTime.replace(' ', 'T')}Z`;
   const utcDate = new Date(isoFormatDate);
 
   // Check if the date is valid
   if (isNaN(utcDate.getTime())) {
     return "Invalid date";
   }
 
   const month = String(utcDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
   const day = String(utcDate.getDate()).padStart(2, '0');
   const year = String(utcDate.getFullYear()).slice(-2); // Get the last two digits of the year
 
   let hours = utcDate.getHours();
   const minutes = String(utcDate.getMinutes()).padStart(2, '0');
   const period = hours >= 12 ? 'PM' : 'AM';
   hours = hours % 12 || 12; // Convert to 12-hour format

   const formattedTime = `${String(hours).padStart(2, '0')}:${minutes} ${period}`;
 
   const formattedDate = `${month}/${day}/${year}`;
   
   return `${formattedDate} ${formattedTime}`;
};



const getCurrentUTCDateTime = () => {
  const now = new Date();

  // Extract the UTC year, month, day, hours, minutes, and seconds
  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(now.getUTCDate()).padStart(2, '0');
  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(now.getUTCMinutes()).padStart(2, '0');
  const seconds = String(now.getUTCSeconds()).padStart(2, '0');

  // Format as "YYYY-MM-DD HH:MM:SS"
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const generateSlug = (digit = 12) => {
  const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let slug = '';
  for (let i = 0; i < digit; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    slug += characters[randomIndex];
  }
  return slug;
};

const getSubstring = (str, length = 30) => {
  return str?.length >= 30 ? (str.substring(0, length) + "...") : str;
};

const currentDate = () => {
  const today = new Date();
  const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(
    today.getDate(),
  ).padStart(2, '0')}`;
  return formattedDate;
};

const convertDateFormat = (dateString) => {
  const [year, month, day] = dateString.split('-');
  const shortYear = year.slice(-2);
  return `${month}/${day}/${shortYear}`;
};

export default {
  compressImage,
  convertUTCToLocalTime,
  getCurrentUTCTime,
  convertUTCToLocalDateTime,
  getCurrentUTCDateTime,
  generateSlug,
  getSubstring,
  currentDate,
  convertDateFormat,
};
