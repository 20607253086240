const actions = {
  LISTING_LOCALS_BEGIN: 'LISTING_LOCALS_BEGIN',
  LISTING_LOCALS_SUCCESS: 'LISTING_LOCALS_SUCCESS',
  LISTING_LOCALS_ERR: 'LISTING_LOCALS_ERR',

  LISTING_LOCALS_CREATE_BEGIN: 'LISTING_LOCALS_CREATE_BEGIN',
  LISTING_LOCALS_CREATE_SUCCESS: 'LISTING_LOCALS_CREATE_SUCCESS',
  LISTING_LOCALS_CREATE_ERR: 'LISTING_LOCALS_CREATE_ERR',

  GET_LISTING_LOCALS_BEGIN: 'GET_LISTING_LOCALS_BEGIN',
  GET_LISTING_LOCALS_SUCCESS: 'GET_LISTING_LOCALS_SUCCESS',
  GET_LISTING_LOCALS_ERR: 'GET_LISTING_LOCALS_ERR',

  LISTING_LOCALS_DELETE_BEGIN: 'LISTING_LOCALS_DELETE_BEGIN',
  LISTING_LOCALS_DELETE_SUCCESS: 'LISTING_LOCALS_DELETE_SUCCESS',
  LISTING_LOCALS_DELETE_ERR: 'LISTING_LOCALS_DELETE_ERR',

  listingLocalsListBegin: () => {
    return {
      type: actions.LISTING_LOCALS_BEGIN,
    };
  },
  listingLocalsListSuccess: (data) => {
    return {
      type: actions.LISTING_LOCALS_SUCCESS,
      data,
    };
  },

  listingLocalsListErr: (err) => {
    return {
      type: actions.LISTING_LOCALS_ERR,
      err,
    };
  },
  createUpdateListingLocalsBegin: () => {
    return {
      type: actions.LISTING_LOCALS_CREATE_BEGIN,
    };
  },
  createUpdateListingLocalsSuccess: (data) => {
    return {
      type: actions.LISTING_LOCALS_CREATE_SUCCESS,
      data,
    };
  },
  createUpdateListingLocalsErr: (err) => {
    return {
      type: actions.LISTING_LOCALS_CREATE_ERR,
      err,
    };
  },
  getListingLocalsBegin: () => {
    return {
      type: actions.GET_LISTING_LOCALS_BEGIN,
    };
  },
  getListingLocalsSuccess: (data) => {
    return {
      type: actions.GET_LISTING_LOCALS_SUCCESS,
      data,
    };
  },

  getListingLocalsErr: (err) => {
    return {
      type: actions.GET_LISTING_LOCALS_ERR,
      err,
    };
  },
  listingLocalsDeleteBegin: () => {
    return {
      type: actions.LISTING_LOCALS_DELETE_BEGIN,
    };
  },

  listingLocalsDeleteSuccess: (data) => {
    return {
      type: actions.LISTING_LOCALS_DELETE_SUCCESS,
      data,
    };
  },

  listingLocalsDeleteErr: (err) => {
    return {
      type: actions.LISTING_LOCALS_DELETE_ERR,
      err,
    };
  },
};
export default actions;
