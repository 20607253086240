const actions = {
  CHARGE_REPORT_LIST_BEGIN: 'CHARGE_REPORT_LIST_BEGIN',
  CHARGE_REPORT_LIST_SUCCESS: 'CHARGE_REPORT_LIST_SUCCESS',
  CHARGE_REPORT_LIST_ERR: 'CHARGE_REPORT_LIST_ERR',

  EXPORT_CHARGE_REPORT_LIST_BEGIN: 'EXPORT_CHARGE_REPORT_LIST_BEGIN',
  EXPORT_CHARGE_REPORT_LIST_SUCCESS: 'EXPORT_CHARGE_REPORT_LIST_SUCCESS',
  EXPORT_CHARGE_REPORT_LIST_ERR: 'EXPORT_CHARGE_REPORT_LIST_ERR',

  chargeReportListingBegin: () => {
    return {
      type: actions.CHARGE_REPORT_LIST_BEGIN,
    };
  },
  chargeReportListingSuccess: (data) => {
    return {
      type: actions.CHARGE_REPORT_LIST_SUCCESS,
      data,
    };
  },
  chargeReportListingErr: (err) => {
    return {
      type: actions.CHARGE_REPORT_LIST_ERR,
      err,
    };
  },

  exportChargeReportListingBegin: () => {
    return {
      type: actions.EXPORT_CHARGE_REPORT_LIST_BEGIN,
    };
  },
  exportChargeReportListingSuccess: (data) => {
    return {
      type: actions.EXPORT_CHARGE_REPORT_LIST_SUCCESS,
      data,
    };
  },
  exportChargeReportListingErr: (err) => {
    return {
      type: actions.EXPORT_CHARGE_REPORT_LIST_ERR,
      err,
    };
  },
  
};
export default actions;
