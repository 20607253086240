import actions from './actions';

const { RESERVATION_LIST_BEGIN, RESERVATION_LIST_SUCCESS, RESERVATION_LIST_ERR,RESERVATION_CLAIM_BEGIN,RESERVATION_CLAIM_SUCCESS,RESERVATION_CLAIM_ERR } = actions;

const initState = {
  list: null,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const ReservationReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case RESERVATION_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESERVATION_LIST_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case RESERVATION_LIST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case RESERVATION_CLAIM_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESERVATION_CLAIM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESERVATION_CLAIM_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    // case GET_HOST_BEGIN:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case GET_HOST_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //   };
    // case GET_HOST_ERR:
    //   return {
    //     ...state,
    //     error: err,
    //     loading: false,
    //   };
    // case HOST_DELETE_BEGIN:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case HOST_DELETE_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //   };
    // case HOST_DELETE_ERR:
    //   return {
    //     ...state,
    //     error: err,
    //     loading: false,
    //   };
    default:
      return state;
  }
};
export default ReservationReducer;
