import actions from "./actions";

const {
    PMS_BEGIN,
    PMS_SUCCESS,
    PMS_ERR,

    CREATE_PMS_BEGIN,
    CREATE_PMS_SUCCESS,
    CREATE_PMS_ERR,

} = actions

const initState = {
    list: null,
    loading: false,
    error: null,
}

const PmsReducer = (state = initState, action) => {
    const { type, data, err } = action;
    switch (type) {
      case PMS_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case PMS_SUCCESS:
        return {
          ...state,
          list: data,
          loading: false,
        };
      case PMS_ERR:
        return {
          ...state,
          error: err,
          loading: false,
        };
      case CREATE_PMS_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case CREATE_PMS_SUCCESS:
        return {
          ...state,
          list: data,
          loading: false,
        };
      case CREATE_PMS_ERR:
        return {
          ...state,
          error: err,
          loading: false,
        };
      default:
        return state;
    }
  };
  export default PmsReducer;
  