// import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect,useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
// import Axios from './axios';
// import QuillEditor from '../../container/common/QuillEditor';
// import Dashboard from './dashboard';
// import Ecommerce from './ecommerce';
// import Features from './features';
// import Gallery from './gallery';
// import Pages from './pages';
// import Users from './users';
// import Widgets from './widgets';
import withAdminLayout from '../../layout/withAdminLayout';

// const Charts = lazy(() => import('./charts'));
// const KnowledgeBase = lazy(() => import('../../container/pages/knowledgeBase/Index'));
// const AllArticle = lazy(() => import('../../container/pages/knowledgeBase/AllArticle'));
// const KnowledgeSingle = lazy(() => import('../../container/pages/knowledgeBase/SingleKnowledge'));
// const Components = lazy(() => import('./components'));
// const Task = lazy(() => import('../../container/task/Index'));
// const Tickets = lazy(() => import('../../container/supportTicket/Index'));
// const AddTicket = lazy(() => import('../../container/supportTicket/AddSupport'));
// const TicketDetails = lazy(() => import('../../container/supportTicket/SupportTicketDetails'));
// const Courses = lazy(() => import('../../container/course/Index'));
// const CourseDetails = lazy(() => import('../../container/course/CourseDetails'));
// const Import = lazy(() => import('../../container/importExport/Import'));
// const Export = lazy(() => import('../../container/importExport/Export'));
// const ToDo = lazy(() => import('../../container/toDo/ToDo'));
// const Note = lazy(() => import('../../container/note/Note'));
// const Kanban = lazy(() => import('../../container/kanban/Index'));
// const Contact = lazy(() => import('../../container/contact/Contact'));
// const ManageListings = lazy(() => import('../../container/pages/manage/Listing'));
// const ManageAddNew = lazy(() => import('../../container/pages/manage/AddNew'));
// const ManageView = lazy(() => import('../../container/pages/manage/View'));

// const Calendars = lazy(() => import('../../container/calendar/Calendar'));
// const Projects = lazy(() => import('./projects'));
// const Myprofile = lazy(() => import('../../container/profile/myProfile/Index'));
// const Chat = lazy(() => import('../../container/chat/ChatApp'));
// const Inbox = lazy(() => import('../../container/email/Email'));
// const Maps = lazy(() => import('./maps'));
// const Editors = lazy(() => import('../../container/pages/Editor'));
// const Icons = lazy(() => import('./icons'));
// const Tables = lazy(() => import('./table'));
// const Jobs = lazy(() => import('../../container/jobSearch/Jobs'));
// const JobDetails = lazy(() => import('../../container/jobSearch/JobSearchDetails'));
// const JobApply = lazy(() => import('../../container/jobSearch/JobApplication'));
const ManageHostList = lazy(() => import('../../container/pages/managehost/HostList'));
const HostAddEdit = lazy(() => import('../../container/pages/managehost/HostAddEdit'));
const HostDetail = lazy(() => import('../../container/pages/managehost/HostDetail'));

const ManageListing = lazy(() => import('../../container/pages/manageList/ManageListing'));
const ListingAddEdit = lazy(() => import('../../container/pages/manageList/ListingAddEdit'));
const ListingDetail = lazy(() => import('../../container/pages/manageList/ListingDetail'));

const ManageReservation = lazy(() => import('../../container/pages/manageReservation/ReservationListing'));
const ReservationDetail = lazy(() => import('../../container/pages/manageReservation/ReservationDetail'));

const ManageCms = lazy(() => import('../../container/pages/manageCms/CmsListing'));
const CmsAddEdit = lazy(() => import('../../container/pages/manageCms/CmsAddEdit'));
const CmsDetail = lazy(() => import('../../container/pages/manageCms/CmsDetail'));

const WaiverPayout = lazy(() => import('../../container/pages/waiverPayout/WaiverPayout'));
//const WaiverPayoutDetail = lazy(() => import('../../container/pages/waiverPayout/WaiverPayoutDetail'));

const ChangePassword = lazy(() => import('../../container/pages/changePassword/ChangePassword'));

const UpSellListing = lazy(() => import('../../container/pages/upSell/UpSellListing'));

// const Firebase = lazy(() => import('./firebase'));
const NotFound = lazy(() => import('../../container/pages/404'));
const PartnerList = lazy(() => import('../../container/pages/managePartner/PartnerList'));
const PartnerAddEdit = lazy(() => import('../../container/pages/managePartner/PartnerAddEdit'));
const PartnerDetail = lazy(() => import('../../container/pages/managePartner/PartnerDetail'));

const ManageChatbox = lazy(() => import('../../container/pages/manageChatbox/ManageChatbox'));

const ManageSystemControl = lazy(() => import('../../container/pages/manageSystemControlbase/systemControlList'));
const SystemControlDetail = lazy(() => import('../../container/pages/manageSystemControlbase/systemControlDetails'));
const AddEditSystemControl = lazy(() => import('../../container/pages/manageSystemControlbase/systemControlAddEdit'));
const ManagePMS = lazy(() => import('../../container/pages/managePms/ManagePMS'));
const ChargeReport = lazy(() => import('../../container/pages/manageChargeReport/ChargeReport'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  const [loginUser, setLoginUser] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const getUser = JSON.parse(localStorage.getItem('user'));
    if (getUser) {
      setLoginUser(getUser);
    }
  }, []);
  return (
    <Suspense fallback={<div className="spin">{/* <Spin /> */}</div>}>
      <Routes>
        {/* <Route path="/design" element={<ManageListings />} />
        <Route path="/addNew" element={<ManageAddNew />} /> */}

        {([1, 3].includes(loginUser.role_id)) && global.checkPermission('reservation-index') && <Route path="/" element={<ManageReservation />} />}
        {([5].includes(loginUser.role_id)) && global.checkPermission('payout-report-index') && <Route path="/" element={<WaiverPayout />} />}

        {global.checkPermission('host-index') && <Route path="host" element={<ManageHostList />} />}
        {global.checkPermission('host-add') && <Route path="host/add" element={<HostAddEdit />} />}
        {global.checkPermission('host-show') && <Route path="host/detail/:hostSlug" element={<HostDetail />} />}
        {global.checkPermission('host-update') && <Route path="host/edit/:hostSlug" element={<HostAddEdit />} />}

        {global.checkPermission('listing-index') && <Route path="listing" element={<ManageListing />} />}
        {global.checkPermission('listing-add') && <Route path="listing/add" element={<ListingAddEdit />} />}
        {global.checkPermission('listing-update') && (
          <Route path="listing/edit/:listingSlug" element={<ListingAddEdit />} />
        )}
        {global.checkPermission('listing-show') && (
          <Route path="listing/detail/:listingSlug" element={<ListingDetail />} />
        )}

        {global.checkPermission('reservation-index') && <Route path="reservation" element={<ManageReservation />} />}
        {global.checkPermission('reservation-show') && (
          <Route path="reservation/detail/:reservationSlug" element={<ReservationDetail />} />
        )}

        {global.checkPermission('cms-index') && <Route path="cms" element={<ManageCms />} />}
        {global.checkPermission('cms-update') && <Route path="cms/edit/:cmsSlug" element={<CmsAddEdit />} />}
        {global.checkPermission('reservation-show') && <Route path="cms/detail/:cmsSlug" element={<CmsDetail />} />}

        {global.checkPermission('payout-report-index') && <Route path="waiver-report" element={<WaiverPayout />} />}
        {global.checkPermission('payout-report-show') && (
          // <Route path="payout-report/detail/:waiverPaymentSlug" element={<WaiverPayoutDetail />} />
          <Route path="waiver-report/detail/:reservationSlug" element={<ReservationDetail />} />
        )}
        {global.checkPermission('security-charge-report-index') && <Route path="charge-report" element={<ChargeReport />} />}

        {global.checkPermission('change-password-update') && (
          <Route path="change-password" element={<ChangePassword />} />
        )}

        {global.checkPermission('upsell-index') && <Route path="upsell-report" element={<UpSellListing />} />}
        {global.checkPermission('upsell-show') && (
          <Route path="upsell-report/detail/:reservationSlug" element={<ReservationDetail />} />
        )}

        {global.checkPermission('partner-index') && <Route path="coverage-providers" element={<PartnerList />} />}
        {global.checkPermission('partner-add') && <Route path="coverage-providers/add" element={<PartnerAddEdit />} />}
        {global.checkPermission('partner-update') && (
          <Route path="coverage-providers/edit/:partnerSlug" element={<PartnerAddEdit />} />
        )}
        {global.checkPermission('partner-show') && (
          <Route path="coverage-providers/detail/:partnerSlug" element={<PartnerDetail />} />
        )}

        {global.checkPermission('inbox-index') && <Route path="message-chatbox" element={<ManageChatbox />} />}

        {global.checkPermission('system-control-index') && <Route path="ai-chat-control" element={<ManageSystemControl />} />}
        {global.checkPermission('system-control-show') && <Route path="ai-chat-control/detail/:systemControlSlug" element={<SystemControlDetail />} />}
        {global.checkPermission('system-control-add') && <Route path="ai-chat-control/add" element={<AddEditSystemControl />} />}
        {global.checkPermission('system-control-update') && <Route path="ai-chat-control/edit/:systemControlSlug" element={<AddEditSystemControl />} />}
        
        {global.checkPermission('pms-integration-index') && <Route path="pms-integration" element={<ManagePMS />} />}

        <Route path="/*" element={<NotFound />} />
        {/* <Route index path="dashboard/*" element={<Dashboard />} />
        <Route path="pages/*" element={<Pages />} />
        <Route path="gallery/*" element={<Gallery />} />
        <Route path="all-articles" element={<AllArticle />} />
        <Route path="knowledgeBase/*" element={<KnowledgeBase />} />
        <Route path="knowledgebaseSingle/:id" element={<KnowledgeSingle />} />
        <Route path="components/*" element={<Components />} />
        <Route path="charts/*" element={<Charts />} />
        <Route path="app/task/*" element={<Task />} />
        <Route path="users/*" element={<Users />} />
        <Route path="app/support/tickets/*" element={<Tickets />} />
        <Route path="app/support/tickets/add" element={<AddTicket />} />
        <Route path="app/support/ticketDetails/:id" element={<TicketDetails />} />
        <Route path="app/course/courseDetails/:id" element={<CourseDetails />} />
        <Route path="app/course/*" element={<Courses />} />
        <Route path="importExport/import" element={<Import />} />
        <Route path="importExport/export" element={<Export />} />
        <Route path="app/to-do" element={<ToDo />} />
        <Route path="app/note/*" element={<Note />} /> */}
        {/* <Route path="app/kanban/*" element={<Kanban />} /> */}
        {/* <Route path="contact/list" element={<Contact />} /> */}
        {/* <Route path="contact/grid" element={<ContactGrid />} /> */}
        {/* <Route path="/addNew" element={<ManageAddNew />} />
        <Route path="/view" element={<ManageView />} /> */}
        {/* <Route path="app/calendar/*" element={<Calendars />} />
        <Route path="features/*" element={<Features />} />
        <Route path="project/*" element={<Projects />} />
        <Route path="profile/myProfile/*" element={<Myprofile />} />
        <Route path="ecommerce/*" element={<Ecommerce />} />
        <Route path="main/chat/*" element={<Chat />} />
        <Route path="email/*" element={<Inbox />} />
        <Route path="maps/*" element={<Maps />} />
        <Route path="editor" element={<Editors />} />
        <Route path="icons/*" element={<Icons />} />
        <Route path="tables/*" element={<Tables />} />
        <Route path="widgets/*" element={<Widgets />} />
        <Route path="app/jobs/*" element={<Jobs />} />
        <Route path="app/job/apply" element={<JobApply />} />
        <Route path="app/jobDetails/:id" element={<JobDetails />} /> */}
        {/* <Route path="firestore/*" element={<Firebase />} />
        <Route path="axios/*" element={<Axios />} />
        <Route path="/new-editor" element={<QuillEditor />} /> */}
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
