import actions from './actions';

const { 
  CMS_LIST_BEGIN, 
  CMS_LIST_SUCCESS, 
  CMS_LIST_ERR, 
  CMS_UPDATE_BEGIN, 
  CMS_UPDATE_SUCCESS, 
  CMS_UPDATE_ERR, 
  GET_CMS_BEGIN, 
  GET_CMS_SUCCESS, 
  GET_CMS_ERR,
  CMS_DELETE_BEGIN,
  CMS_DELETE_SUCCESS,
  CMS_DELETE_ERR
} = actions;

const initState = {
  list: null,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const CmsReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CMS_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CMS_LIST_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case CMS_LIST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CMS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CMS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CMS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_CMS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CMS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_CMS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CMS_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CMS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CMS_DELETE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default CmsReducer;
