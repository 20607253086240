const actions = {
  LISTING_BEGIN: 'LISTING_BEGIN',
  LISTING_SUCCESS: 'LISTING_SUCCESS',
  LISTING_ERR: 'LISTING_ERR',

  LISTING_CREATE_UPDATE_BEGIN: 'LISTING_CREATE_UPDATE_BEGIN',
  LISTING_CREATE_UPDATE_SUCCESS: 'LISTING_CREATE_UPDATE_SUCCESS',
  LISTING_CREATE_UPDATE_ERR: 'LISTING_CREATE_UPDATE_ERR',

  GET_LISTING_BEGIN: 'GET_HOST_BEGIN',
  GET_LISTING_SUCCESS: 'GET_HOST_SUCCESS',
  GET_LISTING_ERR: 'GET_HOST_ERR',

  LISTING_DELETE_BEGIN: 'LISTING_DELETE_BEGIN',
  LISTING_DELETE_SUCCESS: 'LISTING_DELETE_SUCCESS',
  LISTING_DELETE_ERR: 'LISTING_DELETE_ERR',

  HOST_PARTNER_COVERAGE_BEGIN: 'HOST_PARTNER_COVERAGE_BEGIN',
  HOST_PARTNER_COVERAGE_SUCCESS: 'HOST_PARTNER_COVERAGE_SUCCESS',
  HOST_PARTNER_COVERAGE_ERR: 'HOST_PARTNER_COVERAGE_ERR',

  DOWNLOAD_QRCODE_BEGIN: 'DOWNLOAD_QRCODE_BEGIN',
  DOWNLOAD_QRCODE_SUCCESS: 'DOWNLOAD_QRCODE_SUCCESS',
  DOWNLOAD_QRCODE_ERR: 'DOWNLOAD_QRCODE_ERR',

  LISTING_CONTENT_BEGIN: 'LISTING_CONTENT_BEGIN',
  LISTING_CONTENT_SUCCESS: 'LISTING_CONTENT_SUCCESS',
  LISTING_CONTENT_ERR: 'LISTING_CONTENT_ERR',

  CREATE_CHAT_STATUS_BEGIN: 'CREATE_CHAT_STATUS_BEGIN',
  CREATE_CHAT_STATUS_SUCCESS: 'CREATE_CHAT_STATUS_SUCCESS',
  CREATE_CHAT_STATUS_ERR: 'CREATE_CHAT_STATUS_ERR',

  listingBegin: () => {
    return {
      type: actions.LISTING_BEGIN,
    };
  },
  listingSuccess: (data) => {
    return {
      type: actions.LISTING_SUCCESS,
      data,
    };
  },

  listingErr: (err) => {
    return {
      type: actions.LISTING_ERR,
      err,
    };
  },
  listingDetailBegin: () => {
    return {
      type: actions.GET_LISTING_BEGIN,
    };
  },
  listingDetailSuccess: (data) => {
    return {
      type: actions.GET_LISTING_SUCCESS,
      data,
    };
  },

  listingDetailErr: (err) => {
    return {
      type: actions.GET_LISTING_ERR,
      err,
    };
  },
  listingDeleteBegin: () => {
    return {
      type: actions.LISTING_DELETE_BEGIN,
    };
  },
  listingDeleteSuccess: (data) => {
    return {
      type: actions.LISTING_DELETE_SUCCESS,
      data,
    };
  },
  listingDeleteErr: (err) => {
    return {
      type: actions.LISTING_DELETE_ERR,
      err,
    };
  },
  createOrUpdateListingBegin: () => {
    return {
      type: actions.LISTING_CREATE_UPDATE_BEGIN,
    };
  },
  createOrUpdateListingSuccess: (data) => {
    return {
      type: actions.LISTING_CREATE_UPDATE_SUCCESS,
      data,
    };
  },
  createOrUpdateListingErr: (err) => {
    return {
      type: actions.LISTING_CREATE_UPDATE_ERR,
      err,
    };
  },
  hostPartnerCoverageBegin: () => {
    return {
      type: actions.HOST_PARTNER_COVERAGE_BEGIN,
    };
  },
  hostPartnerCoverageSuccess: (data) => {
    return {
      type: actions.HOST_PARTNER_COVERAGE_SUCCESS,
      data,
    };
  },
  hostPartnerCoverageErr: (err) => {
    return {
      type: actions.HOST_PARTNER_COVERAGE_ERR,
      err,
    };
  },
  downloadQrcodeBegin: () => {
    return {
      type: actions.DOWNLOAD_QRCODE_BEGIN,
    };
  },
  downloadQrcodeSuccess: (data) => {
    return {
      type: actions.DOWNLOAD_QRCODE_SUCCESS,
      data,
    };
  },
  downloadQrcodeErr: (err) => {
    return {
      type: actions.DOWNLOAD_QRCODE_ERR,
      err,
    };
  },
  listingContentBegin: () => {
    return {
      type: actions.LISTING_CONTENT_BEGIN,
    };
  },
  listingContentSuccess: (data) => {
    return {
      type: actions.LISTING_CONTENT_SUCCESS,
      data,
    };
  },

  listingContentErr: (err) => {
    return {
      type: actions.LISTING_CONTENT_ERR,
      err,
    };
  },
  chatStatusBegin: () => {
    return {
      type: actions.CREATE_CHAT_STATUS_BEGIN,
    };
  },
  chatStatusSuccess: (data) => {
    return {
      type: actions.CREATE_CHAT_STATUS_SUCCESS,
      data,
    };
  },
  chatStatusErr: (err) => {
    return {
      type: actions.CREATE_CHAT_STATUS_ERR,
      err,
    };
  },
};
export default actions;
