import React from 'react'
import { Spin } from 'antd'
function Spinner({loader}) {
  
  return (
    <>
    { loader &&
    <div className='spinLoader'>
       <Spin />    
    </div>
    } 
    
    {/* <div className='spinLoader'>
    { loader && <Spin />  }     
    </div>    */}
    </>
  )
}

export default Spinner
