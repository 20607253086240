import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import HttpStatus from '../../utility/status.enum';

const { inviteStripeBegin, inviteStripeSuccess, inviteStripeErr } = actions;

const inviteStripeAPIName = '/admin/stripe-invitation';

const stripeInvitation = (values) => {
  return async (dispatch) => {
    dispatch(inviteStripeBegin());
    try {
      const response = await DataService.post(inviteStripeAPIName, values);
      if (response?.data?.status !== HttpStatus.HTTP_OK) {
        dispatch(inviteStripeErr(response?.data?.message));
      } else {
        dispatch(inviteStripeSuccess(response?.data));
      }
      return response?.data;
    } catch (err) {
      dispatch(inviteStripeErr(err));
    }
  };
};

export { stripeInvitation };
