/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length ? `${'0'.repeat(length - number.length)}${number}` : number;
};

const checkPermission = (route) => {
  let permissions = localStorage.getItem('permissions');  
  if (permissions) {
      permissions = JSON.parse(permissions);
      if (permissions.length > 0) {
          let permissionsArray = permissions.map(permission => permission.module_action);
          if (permissionsArray.indexOf(route) >= 0) {
              return true;
          } else {
              return false;
          }
      } else {
          // Permissions array is empty
          return false;
      }
  } else {
      // Permissions not found in localStorage
      return false;
  }
};

export { ellipsis, idGenerator, checkPermission };
