/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
// import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import { Button, Col, Layout, Row, Dropdown, Space } from 'antd';
import propTypes from 'prop-types';
import { Component } from 'react';
import { Scrollbars } from '@pezhmanparsaee/react-custom-scrollbars';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import MenueItems from './MenueItems';
import { LayoutContainer, SmallScreenAuthInfo, TopMenuSearch } from './Style';
// import { FooterStyle } from './Style';
// import TopMenu from './TopMenu';
// import Search from '../components/utilities/auth-info/Search';
import AuthInfo from '../components/utilities/auth-info/info';

const { theme } = require('../config/theme/themeVariables');

const { Sider, Content } = Layout;
// const { Header } = Layout;

const ThemeLayout = (WrappedComponent) => {
  
  class LayoutComponent extends Component {
    
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,
        dropdownText: 'Reservations',
        tablet: false,
        mobile: false,
        laptop: false,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }
    handleTextChange = (newText) => {
      console.log("New Text" , newText);
      // this.setState({ dropdownText: newText });
    };
    

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      const width = window.innerWidth;

      this.setState({
        collapsed: width <= 1200 && true,
        tablet: width >= 768 && width <= 1200, // Tablet Width
        mobile: width < 768, // Mobile Width
        laptop: width >= 1200 // Laptop Width
      });
    }
    
    render() {
      const { collapsed, hide, tablet, mobile, laptop } = this.state;
      const { layoutMode, rtl, topMenu } = this.props;
      const { dropdownText } = this.state;

      const left = !rtl ? 'left' : 'right';
      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };

      const toggleCollapsedMobile = () => {
        if (window.innerWidth <= 990) {
          this.setState({
            collapsed: !collapsed,
          });
        }
      };

      const onShowHide = () => {
        this.setState({
          hide: !hide,
        });
      };

      const SideBarStyle = {
        // margin: '63px 0 0 0',
        margin: '0',
        padding: `${!rtl ? '20px 20px 20px 20px' : '20px 0 20px 20px'}`,
        // overflowY: 'auto',
        overflowY: 'inherit',
        height: '100vh',
        position: 'fixed',
        [left]: 0,
        zIndex: 988,
        background: 'rgba(37, 33, 30, 1)',
      };

      const renderView = ({ style }) => {
        const customStyle = {
          marginRight: 'auto',
          [rtl ? 'marginLeft' : 'marginRight']: '-17px',
        };
        return <div style={{ ...style, ...customStyle }} />;
      };

      const renderThumbVertical = ({ style }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
          [left]: '2px',
        };
        return <div style={{ ...style, ...thumbStyle }} />;
      };

      const renderThumbHorizontal = ({ style }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} />;
      };

      let routeLink = "/reservation";
      const getUser = JSON.parse(localStorage.getItem('user'));
      if(getUser){
        routeLink = getUser?.role_id ===5 ? "/waiver-report" : routeLink;
      }
      const returnHome = (e) =>{
        if(global.isDirtyGlobal){
          const confirmCancel = window.confirm(global.unsaveMessage);
          if (confirmCancel) {
            global.isDirtyGlobal = false;
            window.location.href = routeLink;
          } else {
            e.preventDefault();
          }
        } else {
          window.location.href = routeLink;
        }
      }

      const handleMenuItemClick = (itemName) => {
        this.setState({ dropdownText: itemName });
      };

      const handleAddClass = () => {
        setTimeout(()=>{
        const childDiv = document.querySelector('.ant_dropdown_custom');
        const parentDiv = childDiv?.parentElement?.parentElement; 
        parentDiv.classList.add('mobile-Header-Drodown');
        },100)
      }
      
      return (
        
        <LayoutContainer className='custom_layout_section'>
          <Layout className="layout ">
            {/* <Header
              style={{
                position: 'fixed',
                width: '100%',
                top: 0,
                [!rtl ? 'left' : 'right']: 0,
              }}
            >
              <div className="ninjadash-header-content d-flex">
                <div className="ninjadash-header-content__left">
                  <div className="navbar-brand align-cener-v">
                    <Link
                      className={topMenu && window.innerWidth > 991 ? 'ninjadash-logo top-menu' : 'ninjadash-logo'}
                      onClick={returnHome}
                    >
                      <img
                        src={
                          layoutMode === 'lightMode'
                            ? require(`../static/img/logo_dark.png`)
                            : require(`../static/img/logo_white.svg`)
                        }
                        alt="Logo"
                        height="35"
                      />
                      {<img src={require(`../static/img/logo_dark.png`)} alt="" height="30" />}
                    </Link>
                    {!topMenu || window.innerWidth <= 991 ? (
                      <Button type="link" onClick={toggleCollapsed}>
                        <img
                          src={require(`../static/img/icon/${collapsed ? 'left-bar.svg' : 'left-bar.svg'}`)}
                          alt="menu"
                        />
                      </Button>
                    ) : null}
                  </div>
                </div>
                <div className="ninjadash-header-content__right d-flex">
                  <div className="ninjadash-navbar-menu d-flex align-center-v">
                    {topMenu && window.innerWidth > 991 ? <TopMenu /> : ''}
                  </div>
                  <div className="ninjadash-nav-actions">
                    {topMenu && window.innerWidth > 991 ? (
                      <TopMenuSearch>
                        <div className="top-right-wrap d-flex">
                          <AuthInfo />
                        </div>
                      </TopMenuSearch>
                    ) : (
                      <AuthInfo />
                    )}
                  </div>
                </div>
                <div className="ninjadash-header-content__mobile">
                  <div className="ninjadash-mobile-action">
                    <div className="btn-search" to="#">
                      <Search />
                    </div>

                    <Link className="btn-auth" onClick={onShowHide} to="#">
                      <UilEllipsisV />
                    </Link>
                  </div>
                </div>
              </div>
            </Header> */}
            <div className="ninjadash-header-more">
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <div className="ninjadash-header-more-inner">
                   
                    <SmallScreenAuthInfo hide={hide}>
                      {/* <AuthInfo rtl={rtl} /> */}
                    </SmallScreenAuthInfo>
                   
                  </div>
                </Col>
              </Row>
            </div>
            <Layout>
              {!topMenu || window.innerWidth <= 991 ? (
                <ThemeProvider theme={theme}>
                  
                  <Sider
                    width={215}
                    style={SideBarStyle}
                    collapsed={collapsed}
                    theme={layoutMode === 'lightMode' ? 'light' : 'dark'}
                  >
                    <Link
                      className={topMenu && window.innerWidth > 991 ? 'ninjadash-logo top-menu' : 'ninjadash-logo d-block mb-50'}
                      onClick={(e) => returnHome(e)}
                    >
                      <img
                        src={
                          layoutMode === 'lightMode'
                            ? require(`../static/img/logo_dark.svg`).default
                            : require(`../static/img/logo_white.svg`).default
                        }
                        alt="Logo"
                        width="141"
                      />
                      {/* <img src={require(`../static/img/logo_dark.png`)} alt="" height="30" /> */}
                    </Link>
                  <div className='profile_menu_box'>
                    <Scrollbars
                      className="custom-scrollbar min-h-75"
                      autoHide
                      autoHideTimeout={500}
                      autoHideDuration={200}
                      renderThumbHorizontal={renderThumbHorizontal}
                      renderThumbVertical={renderThumbVertical}
                      renderView={renderView}
                      renderTrackVertical={(props) => <div {...props} className="ninjadash-track-vertical" />}
                    >

                     
                        <MenueItems topMenu={topMenu} toggleCollapsed={toggleCollapsedMobile} onMenuItemClick={handleMenuItemClick} />
                       
                      
                    </Scrollbars>
                    { laptop && <AuthInfo /> } 
                    {/* For laptop screen this module will load */}
                  </div>
                  </Sider>
                </ThemeProvider>
              ) : null}
              <Layout className="atbd-main-layout">
                <Content>
                <div className="tab_view_header">
                    <div className='top_header_section'>
                        <div className='logo_box'>
                          <Link
                          className={topMenu && window.innerWidth > 991 ? 'ninjadash-logo top-menu' : 'ninjadash-logo d-block mb-50'}
                          onClick={(e) => returnHome(e)}
                          >
                          <img
                          src={
                          layoutMode === 'lightMode'
                          ? require(`../static/img/logo_dark.svg`).default
                          : require(`../static/img/logo_white.svg`).default
                          }
                          alt="Logo"
                          width="141"
                          />
                          {/* <img src={require(`../static/img/logo_dark.png`)} alt="" height="30" /> */}
                          </Link>
                        </div>
                        <div className='top_header_content_box'>
                          <div className='rBox'>
                            <div className='cus_drop_down_menu'>
                              <Dropdown trigger={['click']}>
                                <a onClick={(e) => e.preventDefault()}>
                                </a>
                              </Dropdown>
                              <Dropdown
                                trigger={['click']}
                                onClick={handleAddClass}
                                overlayClassName="ant_dropdown_custom"
                                onChange={this.handleTextChange}
                                overlay={
                                  <div className="mobile_view_dropdown">
                                    <MenueItems topMenu={topMenu} toggleCollapsed={toggleCollapsedMobile}  onMenuItemClick={handleMenuItemClick} />
                                    <div className='mobileview_userPro_box'>
                                    { mobile && <AuthInfo />}</div>
                                    {/* For Mobile screen this moudle will load */}
                                  </div>
                                }
                              >
                                <a onClick={(e) => e.preventDefault()}>
                                  <div className="change_dropdown_text">{dropdownText}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 10 7" fill="none">
                                      <path d="M9.20898 6L5.20898 2L1.20898 6" stroke="#25211E" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                  </div>
                                </a>
                              </Dropdown>
                            </div>
                            <div className='custom_auth_box'>
                              { tablet &&  <AuthInfo /> }</div>
                              {/* For Tablet screen this moudle will load */}
                          </div>
                        </div>
                    </div>
                  </div>
                  <WrappedComponent {...this.props} />
                  {/* <FooterStyle className="admin-footer">
                    <Row>
                      <Col md={12} xs={24}>
                        <span className="admin-footer__copyright">
                          © 2023<Link to="#">SovWare</Link>
                        </span>
                      </Col>
                      <Col md={12} xs={24}>
                        <div className="admin-footer__links">
                          <NavLink to="#">About</NavLink>
                          <NavLink to="#">Team</NavLink>
                          <NavLink to="#">Contact</NavLink>
                        </div>
                      </Col>
                    </Row>
                  </FooterStyle> */}
                </Content>
              </Layout>
            </Layout>
          </Layout>
          {window.innerWidth <= 991 ? (
            <span className={collapsed ? 'ninjadash-shade' : 'ninjadash-shade show'} onClick={toggleCollapsed} />
          ) : (
            ''
          )}
        </LayoutContainer>
      );
    
    }
  }

  const mapStateToProps = (state) => {
    return {
      layoutMode: state.ChangeLayoutMode.mode,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  };

  LayoutComponent.propTypes = {
    layoutMode: propTypes.string,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
  };

  return connect(mapStateToProps)(LayoutComponent);
};
export default ThemeLayout;
