const actions = {
  GET_PROFILE_BEGIN: 'GET_PROFILE_BEGIN',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_ERR: 'GET_PROFILE_ERR',

  getProfileBegin: () => {
    return {
      type: actions.GET_PROFILE_BEGIN,
    };
  },

  getProfileSuccess: (data) => {
    return {
      type: actions.GET_PROFILE_SUCCESS,
      data,
    };
  },

  getProfileErr: (err) => {
    return {
      type: actions.GET_PROFILE_ERR,
      err,
    };
  },
};

export default actions;
